import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlacesComponent } from './places.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [PlacesComponent],
  imports: [
    CommonModule,
    FormsModule,
  ], exports: [PlacesComponent]
})
export class PlacesModule { }
