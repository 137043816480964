import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Product, Rubric } from '@app/models';
import { MoldService, ProductService } from '@app/services';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  @ViewChild('inputMobile') inputMobile;
  @ViewChild('inputMedium') inputMedium;
  @ViewChild('inputLarge') inputLarge;
  @ViewChild('inputFull') inputFull;
  @Input() slug: string;
  @Output() hide = new EventEmitter<void>();
  env = environment;
  product = false as unknown as Product;
  brands: Rubric[];
  brandSelected: string;
  varieties: Rubric[];
  varietySelected: string;
  sizes: string[] = [];
  price = '';
  wholesale = '';
  editFacts = false;
  factsInfo: any = [];
  facts: File;
  uploadingFacts: boolean;
  factsUpload: Observable<number>;
  factsDeleted: string;
  editIcon = false;
  iconInfo: any = [];
  icon: File;
  uploadingIcon: boolean;
  iconUpload: Observable<number>;
  iconDeleted: string;
  editPoster = false;
  posterInfo: any = [];
  poster: File;
  uploadingPoster: boolean;
  posterUpload: Observable<number>;
  posterDeleted: string;
  editHero = false;
  heroInfo: any = [];
  hero: {
    full?: File;
    large?: File;
    medium?: File;
    mobile?: File;
  } = {};
  uploadingHero: boolean;
  heroUpload: {
    full?: Observable<number>;
    large?: Observable<number>;
    medium?: Observable<number>;
    mobile?: Observable<number>;
  } = {};
  heroReplaced: {
    full?: string;
    large?: string;
    medium?: string;
    mobile?: string;
  } = {};
  imageInfo: any = [];
  imagesDeleted: string[] = [];
  uploadingImages: boolean;

  get heroList() {
    const list = [];
    if (!this.product) return list;
    for (const hero in this.product.hero)
      if (Object.prototype.hasOwnProperty.call(this.product.hero, hero))
        if (this.product.hero[hero]) list.push({ size: hero, url: this.product.hero[hero] });
    return list;
  }

  get herosDeleted() {
    const list = [];
    for (const size in this.heroReplaced)
      if (Object.prototype.hasOwnProperty.call(this.heroReplaced, size))
        if (!!this.heroReplaced[size]) list.push(this.heroReplaced[size]);
    return list;
  }

  set selectBrand(brand: string) {
    if (!(this.product && this.brands)) return;
    this.setSlug(), this.brandSelected = this.brands.find(b => b.value === brand).title;
  }

  set selectVariety(variety: string) {
    if (!(this.product && this.varieties)) return;
    this.setSlug(), this.varietySelected = this.varieties.find(v => v.value === variety).title;
  }

  get sizePerPrice(): string[] {
    if (this.product.sizes && this.product.sizes.length) {
      if (typeof this.product.price !== 'object') this.product.price = {};
      if (typeof this.product.wholesale !== 'object') this.product.wholesale = {};
      for (const key in this.product.price)
        if (Object.prototype.hasOwnProperty.call(this.product.price, key))
          if (this.product.sizes.find(s => s === key) === undefined)
            delete this.product.price[key],
                  delete this.product.wholesale[key];
      // for (const key in this.product.wholesale)
      //   if (Object.prototype.hasOwnProperty.call(this.product.wholesale, key))
      //     if (this.product.sizes.find(s => s === key) === undefined)
      //       delete this.product.wholesale[key];
      const sizes = [];
      this.product.sizes.forEach(size => {
        if (!(size && this.product.price && this.product.wholesale)) return;
        if (!this.product.price[size]) this.product.price[size] = 0;
        if (!this.product.wholesale[size]) this.product.wholesale[size] = 0;
        sizes.push(size);
      });
      return sizes.length ? sizes : (this.product.price = this.price, this.product.wholesale = this.wholesale, []);
    } else return [];
  }

  constructor(
    private productService: ProductService,
    private storage: AngularFireStorage,
    private moldService: MoldService,
  ) { }

  ngOnInit(): void {
    console.log('getting product: ', this.slug);
    this.productService.getProduct(this.slug).subscribe(product => {
      if (!product.length) return;
      this.product = product[0];
      if (this.product.sizes) this.sizes = [...this.product.sizes];
      if (!this.product.ingredients) this.product.ingredients = { main: '' };
      console.log('product: ', product);
    });
    this.moldService.getMold('brands').subscribe(mold => {
      this.brands = mold[0].brands;
      if (this.product) this.selectBrand = this.product.brand;
    });
    this.moldService.getMold('varieties').subscribe(mold => {
      this.varieties = mold[0].varieties;
      if (this.product) this.selectVariety = this.product.variety;
    });
  }

  addDescription() {
    if (!this.product.description) this.product.description = [];
    this.product.description.push("");
  }

  addFootnote() {
    if (!this.product.ingredients) this.product.ingredients = { main: '', footnotes: [] };
    else if (!this.product.ingredients.footnotes) this.product.ingredients.footnotes = [];
    this.product.ingredients.footnotes.push("");
  }

  addSize() {
    if (!this.product.sizes) this.product.sizes = [];
    this.sizes.push(""), this.product.sizes.push("");
  }

  typeOf(data) {
    return typeof data;
  }

  setSlug() {
    this.product.slug = this.product.brand + '-' + this.product.variety;
  }

  async saveProduct(thenClose?: boolean) {
    if (!this.product.title.trim()) return alert("Product title is required.");
    if (!this.product.index) this.product.index = 0;
    if (this.product.description && typeof this.product.description === 'object')
      this.product.description = this.product.description.filter(_ => !!_);
    if (this.product.sizes && typeof this.product.sizes === 'object')
      this.product.sizes = this.product.sizes.filter(_ => !!_);
    if (this.product.ingredients.footnotes && typeof this.product.ingredients.footnotes === 'object')
      this.product.ingredients.footnotes = this.product.ingredients.footnotes.filter(_ => !!_);

    const uploads = [];
    if (this.imageInfo.length) uploads.push(this.uploadImages());
    if (this.imagesDeleted.length) this.imagesDeleted.forEach(url =>
      uploads.push(this.productService.deleteImage(url)));
    if (this.editFacts) uploads.push(this.uploadFacts());
    else if (this.factsDeleted)
      uploads.push(this.productService.deleteImage(this.factsDeleted));
    if (this.editIcon) uploads.push(this.uploadIcon());
    else if (this.iconDeleted)
      uploads.push(this.productService.deleteImage(this.iconDeleted));
    if (this.editPoster) uploads.push(this.uploadPoster());
    else if (this.posterDeleted)
      uploads.push(this.productService.deleteImage(this.posterDeleted));
    if (this.editHero) for (const size in this.hero)
      if (Object.prototype.hasOwnProperty.call(this.hero, size))
        uploads.push(this.uploadHero(size)), this.uploadingHero = true;
    if (this.herosDeleted.length) this.herosDeleted.forEach(url =>
      uploads.push(this.productService.deleteImage(url)));
    if (uploads.length) await Promise.all(uploads).then(() => this.uploadingHero = false);

    this.productService.validateProduct(this.product.slug).subscribe(assert =>
      (assert.empty || assert.docs.length === 1 && assert.docs[0].id === this.product.id) ?
        this.productService.updateProduct(this.product).then(() => thenClose && this.hide.emit())
          .catch(error => console.log("There was an error saving the product: ", error)) :
        alert(`A product with the slug '${this.product.slug}' is already in use.`));
    console.log('Product updated: ', this.product);
  }

  addHero(size, event) {
    const element = event[0];
    const reader = new FileReader();
    this.heroInfo.push({ name: element.name, size });
    reader.onload = (e: any) => this.heroInfo.find(i => i.size === size).preview = e.target.result;
    reader.readAsDataURL(this.hero[size] = element);
  }

  removeHero(input, size) {
    input.value = '', this.heroInfo = this.heroInfo.filter(i => i.size !== size), this.hero[size] = null;
  }

  replaceHero(size) {
    this.heroReplaced[size] = this.product.hero[size];
    this.product.hero[size] = null;
  }

  restoreHero(input, hero?: string, single?) {
    if (input === false)
      this.product.hero[hero] = this.heroReplaced[hero],
      this.heroReplaced[hero] = null, this.removeHero(single, hero);
    else for (const size in this.heroReplaced)
      if (Object.prototype.hasOwnProperty.call(this.heroReplaced, size))
        if (this.heroReplaced[size])
          this.product.hero[size] = this.heroReplaced[size],
          this.heroReplaced[size] = null, this.removeHero(input[size], size);
  }

  uploadHero(size: string) {
    return new Promise((resolve, reject) => {
      if (this.heroInfo.find(i => i.size === size) && this.hero[size]) {
        const ext = this.heroInfo.find(i => i.size === size).name.split('.').pop();
        const heroSize = size === 'mobile' ? '_crop' :
          size === 'medium' ? '-hero_medium' :
          size === 'large' ? '-hero_large' : '-hero';
        const name = this.product.slug + heroSize + '.' + ext;
        const task = this.productService.uploadHero(size, this.hero[size], name);

        this.heroUpload[size] = task.percentageChanges();
        task.then(stored => {
          this.storage.ref(stored.metadata.fullPath).getDownloadURL().subscribe(url => {
            if (!this.product.hero) this.product.hero = {};
            this.product.hero[size] = url;
            console.log('hero uploaded: ', stored);
            resolve(true);
          });
        }).catch(error => {
          console.log('Error uploading image: ', error);
          reject();
        });
      } else resolve(false);
    });
  }

  addImage(files) {
    console.log('images added: ', files);
    for (const file in files) {
      if (Object.prototype.hasOwnProperty.call(files, file)) {
        const image = files[file];
        const reader = new FileReader();
        this.imageInfo.push({ image });
        console.log('image info: ', this.imageInfo);
        reader.onload = (e: any) => this.imageInfo.find(i => i.image === image).preview = e.target.result;
        reader.readAsDataURL(image);
      }
    }
  }

  removeImage(data) {
    this.imageInfo = this.imageInfo.filter(i => i.image.name !== data.image.name);
  }

  replaceImage(url) {
    this.imagesDeleted.push(url);
    this.product.images = this.product.images.filter(u => u !== url);
  }

  restoreImage(url) {
    this.product.images.push(url);
    this.imagesDeleted = this.imagesDeleted.filter(u => u !== url);
  }

  uploadImages() {
    return new Promise((resolve, reject) => {
      if (this.imageInfo.length) {
        this.uploadingImages = true;
        const promises = this.imageInfo.map(data => {
          const image = data.image;
          const ext = image.name.split('.').pop();
          const name = new Date().valueOf().toString(32) + '.' + ext;
          const task = this.productService.uploadImage(this.product.slug, image, name);

          return new Promise((res, rej) => {
            task.then(stored => {
              this.storage.ref(stored.metadata.fullPath).getDownloadURL().subscribe(url => {
                if (!this.product.images) this.product.images = [];
                this.product.images.push(url);
                console.log('image uploaded: ', stored);
                res(true);
              });
            }).catch(error => {
              console.log('Error uploading image: ', error);
              rej(error);
            });
          });
        });
        Promise.all(promises).then(() => (this.uploadingImages = false, resolve(true)))
          .catch((error) => reject(error));
      } else resolve(false);
    });
  }

  addFacts(event) {
    const element = event[0];
    const reader = new FileReader();
    this.factsInfo = [{ name: element.name }];
    reader.onload = (e: any) => this.factsInfo[0].preview = e.target.result;
    reader.readAsDataURL(this.facts = element);
  }

  removeFacts(input) {
    input.value = '', this.factsInfo = [], this.facts = null;
  }

  deleteFacts() {
    this.factsDeleted = this.product.facts;
    this.product.facts = null;
  }

  restoreFacts() {
    this.product.facts = this.factsDeleted;
    this.factsDeleted = null;
  }

  uploadFacts() {
    return new Promise((resolve, reject) => {
      if (this.factsInfo.length && this.facts) {
        const ext = this.factsInfo[0].name.split('.').pop();
        const name = this.product.slug + '.' + ext;
        const task = this.productService.uploadFacts(this.facts, name);

        this.uploadingFacts = true;
        // observe percentage changes
        this.factsUpload = task.percentageChanges();
        // get notified when the download URL is available
        task.then(stored => {
          this.storage.ref(stored.metadata.fullPath).getDownloadURL().subscribe(url => {
            this.uploadingFacts = false;
            this.product.facts = url;
            console.log('facts uploaded: ', stored);
            resolve(true);
          });
        }).catch(error => {
          console.log('Error uploading facts image: ', error);
          this.uploadingFacts = false;
          reject();
        });
      } else resolve(false);
    });
  }

  addIcon(event) {
    const element = event[0];
    const reader = new FileReader();
    this.iconInfo = [{ name: element.name }];
    reader.onload = (e: any) => this.iconInfo[0].preview = e.target.result;
    reader.readAsDataURL(this.icon = element);
  }

  removeIcon(input) {
    input.value = '', this.iconInfo = [], this.icon = null;
  }

  deleteIcon() {
    this.iconDeleted = this.product.icon;
    this.product.icon = null;
  }

  restoreIcon() {
    this.product.icon = this.iconDeleted;
    this.iconDeleted = null;
  }

  uploadIcon() {
    return new Promise((resolve, reject) => {
      if (this.iconInfo.length && this.icon) {
        const ext = this.iconInfo[0].name.split('.').pop();
        const name = this.product.slug + '.' + ext;
        const task = this.productService.uploadIcon(this.icon, name);

        this.uploadingIcon = true;
        // observe percentage changes
        this.iconUpload = task.percentageChanges();
        // get notified when the download URL is available
        task.then(stored => {
          this.storage.ref(stored.metadata.fullPath).getDownloadURL().subscribe(url => {
            this.uploadingIcon = false;
            this.product.icon = url;
            console.log('icon uploaded: ', stored);
            resolve(true);
          });
        }).catch(error => {
          console.log('Error uploading icon image: ', error);
          this.uploadingIcon = false;
          reject();
        });
      } else resolve(false);
    });
  }

  addPoster(event) {
    const element = event[0];
    const reader = new FileReader();
    this.posterInfo = [{ name: element.name }];
    reader.onload = (e: any) => this.posterInfo[0].preview = e.target.result;
    reader.readAsDataURL(this.poster = element);
  }

  removePoster(input) {
    input.value = '', this.posterInfo = [], this.poster = null;
  }

  deletePoster() {
    this.posterDeleted = this.product.poster;
    this.product.poster = null;
  }

  restorePoster() {
    this.product.poster = this.posterDeleted;
    this.posterDeleted = null;
  }

  uploadPoster() {
    return new Promise((resolve, reject) => {
      if (this.posterInfo.length && this.poster) {
        const ext = this.posterInfo[0].name.split('.').pop();
        const name = this.product.slug + '.' + ext;
        const task = this.productService.uploadPoster(this.poster, name);

        this.uploadingPoster = true;
        // observe percentage changes
        this.posterUpload = task.percentageChanges();
        // get notified when the download URL is available
        task.then(stored => {
          this.storage.ref(stored.metadata.fullPath).getDownloadURL().subscribe(url => {
            this.uploadingPoster = false;
            this.product.poster = url;
            console.log('poster uploaded: ', stored);
            resolve(true);
          });
        }).catch(error => {
          console.log('Error uploading poster image: ', error);
          this.uploadingPoster = false;
          reject();
        });
      } else resolve(false);
    });
  }

}
