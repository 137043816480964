import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SquareService {

  catalogUrl = 'https://us-central1-pecanmilk.cloudfunctions.net/catalog';
  listCatalogUrl = 'https://us-central1-pecanmilk.cloudfunctions.net/listCatalog';

  constructor(private http: HttpClient) { }

  catalog(sub) {
    return this.http.post<any>(this.catalogUrl, sub);
  }

  listCatalog() {
    return this.http.get<any>(this.listCatalogUrl);
  }

}


