<nb-layout *ngIf="!loadingUser" [ngClass]="{'in-view': env.overlay}">

  <nb-layout-header fixed *ngIf="!fullPage">
    <app-header
      [menuOpen]="menuOpen"
      [mobileMenuOpen]="mobileMenuOpen"
      [shortcutSize]="shortcutSize"
      (openMenu)="openMenu($event)"
      (toggleMenu)="toggleMenu()">
    </app-header>
  </nb-layout-header>

  <nb-sidebar [state]="menuOpen?'expanded':'compacted'" responsive *ngIf="!fullPage" tag="main">
    <app-nav-menu [expanded]="menuOpen||mobileMenuOpen" (toggleMenu)="toggleMenu()"></app-nav-menu>
  </nb-sidebar>

  <nb-sidebar right tag="short" [state]="!mobileMenuOpen&&'collapsed'" *ngIf="!fullPage">
    <button nbButton ghost status="success"><nb-icon icon="star"></nb-icon></button>
    <button nbButton ghost status="success"><nb-icon icon="message-circle"></nb-icon></button>
    <button nbButton ghost status="success"><nb-icon icon="options-2"></nb-icon></button>
    <button nbButton ghost status="success"><nb-icon icon="bell"></nb-icon></button>
    <button nbButton ghost status="success" (click)="toggleMenu();logout()">
      <nb-icon icon="log-out"></nb-icon>
    </button>
  </nb-sidebar>

  <nb-layout-column>
    <div class="wrapper" [ngClass]="{'faded': env.overlay}"><router-outlet></router-outlet></div>
    <app-overlay></app-overlay>
  </nb-layout-column>

  <nb-layout-footer fixed *ngIf="!fullPage">
    <app-footer></app-footer>
  </nb-layout-footer>

</nb-layout>

<div class="interface-loading" [ngClass]="{'done': !loadingUser}">
  <nb-card [nbSpinner]="loadingUser" accent="success">
    <h6>Loading Portal...</h6>
  </nb-card>
</div>