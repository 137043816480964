import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Mold } from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class MoldService {

  constructor(
    private firestore: AngularFirestore,
  ) { }

  getMold(mold: string) {
    return this.firestore.collection<Mold>('molds', ref => ref.where('type', '==', mold)).valueChanges({ idField: 'id' });
  }
}
