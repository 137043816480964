<header>
    <div class="menu-button" [ngClass]="{'open': menuOpen}">
        <button class="view-btn list-view" title="List View" (click)="emitOpenMenu(true)">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-list">
                <line x1="8" y1="6" x2="21" y2="6"></line>
                <line x1="8" y1="12" x2="21" y2="12"></line>
                <line x1="8" y1="18" x2="21" y2="18"></line>
                <line x1="3" y1="6" x2="3.01" y2="6"></line>
                <line x1="3" y1="12" x2="3.01" y2="12"></line>
                <line x1="3" y1="18" x2="3.01" y2="18"></line>
            </svg>
        </button>
        <img (click)="emitOpenMenu(false)" class="menu-logo" src="../assets/images/logo-bike-and-word-mark.png" alt="">
    </div>
    <div class="menu-brand" [ngClass]="{'shifted': !menuOpen}" (click)="emitOpenMenu(!menuOpen)">
        <span>{{ portalTitle }}</span>
    </div>
    <div class="menu-shortcuts">
        <nb-actions [size]="shortcutSize">
            <nb-action title="Shortcuts" icon="star"></nb-action>
            <nb-action title="Chat" icon="message-circle"></nb-action>
            <nb-action title="Theme" icon="options-2"></nb-action>
            <nb-action title="Notifications" icon="bell"></nb-action>
            <nb-action title="Log Out" icon="log-out" (click)="logout()"></nb-action>
        </nb-actions>
        <span class="menu-fname">{{userFirst()}}</span>
        <img class="menu-avatar"
          [src]="user.avatar || '../../assets/images/default.png'"
          [title]="user.first + ' ' + user.last"
          [alt]="user.first + ' ' + user.last">
        <nb-search type="rotate-layout" class="menu-search"></nb-search>
        <button nbButton ghost class="menu-more" (click)="emitToggleMenu()">
            <nb-icon [icon]="mobileMenuOpen?'close':'more-horizontal-outline'"></nb-icon>
        </button>
    </div>
</header>
