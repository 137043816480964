import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { StoreLocation } from '@app/models';
import { Observable } from 'rxjs';
import { map as m } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
  ) { }

  getLocations(): Observable<StoreLocation[]> {
    return this.firestore.collection<StoreLocation>('locations', ref => ref.orderBy('name', 'asc'))
      .valueChanges({ idField: 'id' });
  }

  getLocation(id: string) {
    return this.firestore.collection<StoreLocation>('locations').doc(id).valueChanges({ idField: 'id' });
  }

  addLocation(location: StoreLocation) {
    return this.firestore.collection<StoreLocation>('locations').add(location);
  }

  updateLocation(location: StoreLocation) {
    return this.firestore.collection<StoreLocation>('locations').doc(location.id).update(location);
  }

  uploadImage(location: string, image: File, name: string) {
    return this.storage.upload(`images/location-images/${location}/${name}`, image);
  }

  deleteImage(downloadUrl) {
    return this.storage.storage.refFromURL(downloadUrl).delete();
  }

}
