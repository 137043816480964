import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Order, orderNote } from '@app/models';
import { Observable } from 'rxjs';
import { map as m } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private firestore: AngularFirestore,
  ) { }

  getOrders(): Observable<Order[]> {
    return this.firestore.collection<Order>('orders', ref => ref.orderBy('createdAt', 'desc')).valueChanges({ idField: 'id' });
  }

  getNewOrders(): Observable<Order[]> {
    return this.firestore.collection<Order>('orders', ref => ref.where('status', '==', 'created').orderBy('createdAt', 'asc')).valueChanges({ idField: 'id' });
  }

  getUnfullfilledOrders(): Observable<Order[]> {
    return this.firestore.collection<Order>('orders', ref =>
      ref.where('status', 'in', ['created', 'available', 'dispatched'])
      .orderBy('createdAt', 'desc'))
      .valueChanges({ idField: 'id' });
  }

  getOrder(id: string) {
    return this.firestore.collection<Order>('orders', ref => ref.where('id', '==', id)).valueChanges({ idField: 'id' });
  }

  updateOrder(order: Order) {
    return this.firestore.collection<Order>('orders').doc(order.id).update(order);
  }

  addOrderNote(order: Order, note: orderNote) {
    if (!order.notes) order.notes = [];
    return order.notes.push(note), this.updateOrder(order);
  }

}
