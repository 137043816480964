import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
// declare var google;

@Component({
    selector: 'app-google-places',
    template: `
      <input class="form-control"
        type="text"
        (keyup)="keyUp.emit()"
        [(ngModel)]="autocompleteInput"
        #address
        >
    `,
})
export class PlacesComponent implements OnInit, AfterViewInit {

    @ViewChild('address') address: any;
    @Input() adressType: string;
    @Output() setAddress: EventEmitter<any> = new EventEmitter();
    @Output() keyUp: EventEmitter<any> = new EventEmitter();
    autocompleteInput: string;

    constructor() {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.getPlacesAutocomplete();
    }

    private getPlacesAutocomplete() {
        const autocomplete = new google.maps.places.Autocomplete(this.address.nativeElement,
            {
                componentRestrictions: { country: 'US' },
                types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
            });
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace();
            this.setAddress.emit(place);
        });
    }

}
