import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Product } from '@app/models';
import { Observable } from 'rxjs';
import { map as m } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
  ) { }

  getProducts(): Observable<Product[]> {
    return this.firestore.collection<Product>('products', ref => ref.orderBy('slug', 'asc')).valueChanges({ idField: 'id' });
    // return this.firestore.collection<Product>('products', ref => ref.orderBy('slug', 'asc')).snapshotChanges().pipe(
    //     m(_ => _.map(a => {
    //       const product = a.payload.doc.data() as Product;
    //       const id = a.payload.doc.id;
    //       return { id, ...product };
    //     }))
    //   );
  }

  getProduct(slug: string) {
    return this.firestore.collection<Product>('products', ref => ref.where('slug', '==', slug)).valueChanges();
  }

  validateProduct(slug: string) {
    return this.firestore.collection<Product>('products', ref => ref.where('slug', '==', slug)).get();
  }

  addProduct(product: Product) {
    return this.firestore.collection<Product>('products').add(product);
  }

  updateProduct(product: Product) {
    return this.firestore.collection<Product>('products').doc(product.id).update(product);
  }

  uploadHero(size: string, image: File, name: string) {
    return this.storage.upload(`images/product-hero/${size}/${name}`, image);
  }

  uploadImage(product: string, image: File, name: string) {
    return this.storage.upload(`images/product-images/${product}/${name}`, image);
  }

  deleteImage(downloadUrl) {
    return this.storage.storage.refFromURL(downloadUrl).delete();
  }

  uploadPoster(image: File, name: string) {
    return this.storage.upload('images/product-poster/' + name, image);
  }

  uploadIcon(image: File, name: string) {
    return this.storage.upload('images/product-icon/' + name, image);
  }

  uploadFacts(image: File, name: string) {
    return this.storage.upload('images/product-facts/' + name, image);
  }

}
