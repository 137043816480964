import { AuthService, LocationService } from '@app/services';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StoreLocation, User } from '@app/models';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class StoreLocationComponent implements OnInit {

  @Input() id: string;
  @Output() hide = new EventEmitter<void>();
  env = environment;
  location = false as unknown as StoreLocation;
  locationSet: boolean;
  place: any;
  address: string;

  get user(): User {
    return this.auth.user
  }

  center = { lat: 33.7674828, lng: -84.5025311 };
  mapOptions: google.maps.MapOptions = {
    center: this.center,
    zoom: 10,
  };

  markerOptions: google.maps.MarkerOptions = {};

  spots: { id: number; lat: number; lng: number }[] = [];

  constructor(
    private locationService: LocationService,
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    console.log("location", this.id)
    this.locationService.getLocation(this.id).subscribe(location => {
      this.location = location;
      console.log('Store Location Overlay: ', this.location, location);
    });
  }

  commalize(n: any) {
    if (!n) { return '0.00'; }
    const digits = Number(n).toFixed(2).split('').reverse();
    const decimals = digits.splice(0, 3);
    for (let i = 0, l = digits.length; i < l; i++) { if (i > 0 && i % 3 === 0) { digits[i] += ','; } }
    return decimals.concat(digits).reverse().join('');
  }

  setAddress(place: google.maps.places.PlaceResult) {
    this.address = place.formatted_address;
    this.place = place;
    if (this.address) {
      this.locationSet = true;
      const latLng = place.geometry.location;
      this.center = {
        lat: latLng.lat(),
        lng: latLng.lng()
      }
      this.mapOptions.zoom = 18;
    }
    console.log("got address:", this.address, this.place, this.center);
  }

  async saveAddress() {
    const location = this.location;
    location.address = this.address;
    location.latLng = this.center;
    console.log("Got location: ", location);
    await this.locationService.updateLocation(this.location);
    this.hide.emit();
  }


}
