import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { NbIconLibraries, NbSearchService, NbSidebarService } from '@nebular/theme';
import { AuthService } from './services';

@Component({
  selector: 'app-portal',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  env = environment;
  menuOpen = false;
  mobileMenuOpen = false;
  shortcutSize = 'small';

  get fullPage(): boolean {
    return this.auth.authenticated === false;
  }

  get loadingUser() {
    return !(!!this.auth.user || this.auth.authenticated === false);
  }

  constructor(
    private auth: AuthService,
    private router: Router,
    private searchService: NbSearchService,
    private sidebarService: NbSidebarService,
    private iconLibraries: NbIconLibraries,
  ) {
    searchService.onSearchSubmit()
      .subscribe((data: any) => {
        console.log("search data: ", data.term, data);
      });
    iconLibraries.registerFontPack('font-awesome', { iconClassPrefix: 'fa' });
  }

  ngOnInit(): void {
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 1199 && this.menuOpen === false) this.menuOpen = true;
    if (window.innerWidth < 720 && this.shortcutSize !== 'tiny') this.shortcutSize = 'tiny';
    else if (window.innerWidth >= 720 && this.shortcutSize !== 'small') this.shortcutSize = 'small';
    if (window.innerWidth > 575 && this.mobileMenuOpen) this.mobileMenuOpen = false;
  }

  logout() {
    this.router.navigate(['/authenticate/logout']);
  }

  openMenu(bool): void {
    if (window.innerWidth > 1199) this.menuOpen = bool;
    else if (window.innerWidth < 576) this.toggleMenu();
  }

  toggleMenu() {
    if (window.innerWidth < 576) {
      this.mobileMenuOpen = !this.mobileMenuOpen;
      this.sidebarService.toggle(false, 'main');
      this.sidebarService.toggle(false, 'short');
      console.log('smO: ', this.mobileMenuOpen);
    }
  }

}
