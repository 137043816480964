import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit {

  env = environment;

  constructor() { }

  ngOnInit(): void {
  }

  closeOverlay() {
    for (const view in this.env.display) this.env.display[view] = false, this.env.overlay = false;
  }

}
