<div class="row">
  <div class="col-md-4 pr0">
    <nb-card>
      <nb-card-body class="text-center">
        <h1 class="text-success font-weight-light">Order #{{ order?.number }}</h1>
        <hr>
        <span style="font-size: 16px;">
          {{ formatDate(order?.createdAt, true) }}
        </span>
      </nb-card-body>
    </nb-card>
    <nb-card>
      <nb-card-body>
        <h2 class="text-right text-success font-weight-light">
          {{ order?.orderType === 'public' ?
            'Public order by anonymous customer' :
            order?.orderType === 'investment' ? 'Investment made by ' + order?.client?.name :
            order?.orderType === 'donation' ? 'Donation made by ' + order?.client?.name :
            'Client order by ' + order?.client?.name
          }}
        </h2>
        <hr>
        <table width="100%" cellpadding="5" class="summary-table">
          <tr>
              <td>{{ order?.orderType === 'donation' ? '' : 'Order ' }}Date</td>
              <td><strong>{{ formatDate(order.createdAt) }}</strong></td>
          </tr>
          <tr>
              <td>Time</td>
              <td><strong>{{ formatTime(order.createdAt) }}</strong></td>
          </tr>
          <tr>
              <td>Status</td>
              <td>
                <strong class="text-capitalize" [ngClass]="{
                  'text-info': order.status==='fullfilled',
                  'text-dark': order.status==='stalled',
                  'text-success': order.status === 'delivered',
                  'text-primary': order.status === 'created' || order.status === 'dispatched',
                  'text-warning': order.status === 'received' || order.status === 'refunded',
                  'text-danger': order.status === 'delayed' || order.status === 'cancelled'
              }">
                  {{ order.status === 'drafted' ? 'In Basket' : order.status }}
              </strong>
              </td>
          </tr>
          <ng-container *ngIf="order.refund">
              <hr>
              <tr>
                  <td>Refund Date</td>
                  <td><strong>{{ formatDate(order.refund.created * 1000) }}</strong></td>
              </tr>
              <tr>
                  <td>Time</td>
                  <td><strong>{{ formatTime(order.refund.created * 1000) }}</strong></td>
              </tr>
          </ng-container>
          <hr>
          <tr class="summary-footer" *ngIf="order?.orderType==='investment'">
              <td class="font-weight-light">Shares Purchased</td>
              <td class="text-success">{{ order.total / 20 }}</td>
          </tr>
          <ng-container *ngIf="order?.orderType==='donation'||order?.orderType==='investment'; else totals">
            <tr class="summary-footer">
                <td>{{ order?.orderType === 'investment' ? 'Investment' : 'Donation' }} Total</td>
                <td class="text-success" [ngClass]="{'text-warning': order.status === 'refunded'}">${{ commalize(order.total) }}</td>
            </tr>
          </ng-container>
          <ng-template #totals>
            <tr>
                <td>Subtotal</td>
                <td>${{ commalize(order.total) }}</td>
            </tr>
            <tr>
                <td>Delivery Charge</td>
                <td>${{ commalize(order.totalShipping) }}</td>
            </tr>
            <tr>
                <td>Tax</td>
                <td>${{ commalize(order.totalTax) }}</td>
            </tr>
            <tr class="summary-footer">
                <td>Total</td>
                <td class="text-success" [ngClass]="{'text-warning': order.status === 'refunded'}">${{ commalize(order.totalTax + order.totalShipping + order.total) }}</td>
            </tr>
          </ng-template>
          <hr>
          <tr>
              <td>
                  {{ order.status === 'refunded' ? 'Returned to' : 'Charged to' }}
                  <span style="text-transform: capitalize;">
                      {{ (order?.charge?.payment?.cardDetails.card.cardType || order?.charge?.payment?.cardDetails.card.cardBrand || '').toLocaleLowerCase() }} card
                  </span>
              </td>
              <td>
                  <strong style="font-weight: 700; opacity: 0.2; text-shadow: 0 1px 2px #00000061;">
                    {{ order?.charge?.payment?.cardDetails.card.cardBrand.replace('_', ' ') }} **** {{ order?.charge?.payment?.cardDetails.card.last4 }}
                  </strong>
              </td>
          </tr>
        </table>
        <hr>
        <h2 class="text-right text-success font-weight-light mb-0">{{ order?.patron ? 'Patron' : 'Cardholder' }} Information</h2>
      </nb-card-body>
      <nb-card class="mb-0 hover">
        <nb-card-header class="text-hint text-right">{{ order?.patron ? order?.patron.name : order?.cardholder?.first }} {{ order?.cardholder?.last }}</nb-card-header>
        <nb-card-body>
          <ng-container *ngIf="order?.patron">
            <span class="d-block pb-3 text-hint text-monospace text-right" style="font-size: 1.4em;">{{ order?.patron.email }}</span>
            <h6 class="text-hint">{{ order?.patron.phone }}</h6>
            <h6 class="text-hint">{{ order?.patron.address }}</h6>
          </ng-container>
          <h6 class="text-hint" *ngIf="order.cardholder?.phone">{{ order.cardholder?.phone }}</h6>
          <h6 class="text-hint">{{ order.cardholder?.address.billing.formatted }}</h6>
          <span class="text-hint text-monospace">{{ order.cardholder?.email }}</span>
        </nb-card-body>
      </nb-card>
    </nb-card>
    <nb-card class="hover" *ngIf="order?.orderType==='client'||order?.orderType==='public'">
      <nb-card-body>
        <h2 class="font-weight-light mb-0">Deliver To:</h2>
        <div class="address-name text-success">
          {{
            order.recipient && order.recipient.trim() ?
            order.recipient.trim() : [order.cardholder?.first, order.cardholder?.last].join(' ').trim()
          }}
        </div>
        <div class="address text-success font-weight-bolder mb-1" *ngIf="order.cardholder?.address.shipping.unit">
          {{ order.cardholder?.address.shipping.unit }}
        </div>
        <div class="address text-success">
          {{ order.cardholder?.address.shipping.formatted }}
        </div>
        <div class="address text-success">
          <i>
            {{ order.recipientPhone && order.recipientPhone.trim() }}
          </i>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
  <div class="col-md-8">
    <ng-container *ngIf="productTotal">
      <h2 class="text-center text-success font-weight-light mb-4">
        {{ productTotal }} Product{{ productTotal > 1 ? 's' : '' }}
        {{
            order.status === 'created' || order.status === 'stalled' ? 'Purchased' :
            order.status === 'fulfilled' ? 'Ordered' :
            order.status === 'delivered' ? 'Delivered' :
            order.status === 'cancelled' ? 'Cancelled' :
            order.status === 'refunded' ? 'Reimbursed' :
            order.status === 'delayed' ? 'On Hold' :
            'Coming'
        }}
      </h2>
      <div class="product row" *ngFor="let product of order.products" [ngClass]="{'cancelled': order.status === 'cancelled'}">
        <div class="col-sm-3 col-5 ml-auto mb-2">
          <img [src]="product.poster || '../../../assets/images/logo-icon.png'">
        </div>
        <div class="col-sm-8">
          <div class="card">
            <div class="card-body">
              <h6 class="card-title text-primary text-right">
                {{
                  product.quantity + ' ' +
                  product.size + (product.quantity > 1 ? 's' : '') +
                  ' of ' + product.title
                }}
              </h6>
              <ul>
                <li>
                  <strong>BRAND: </strong>
                  <span class="text-right" style="text-transform: capitalize;">{{ product.brand.split('-').join(' ') }}</span>
                </li>
                <li>
                  <strong>VARIETY: </strong>
                  <span class="text-right" style="text-transform: capitalize;">{{ product.variety }}</span>
                </li>
                <li>
                  <strong>SIZE: </strong>
                  <span class="text-right" style="text-transform: capitalize;">{{ product.size }}</span>
                </li>
                <li>
                  <strong>QTY: </strong>
                  <span class="text-right">{{ product.quantity }} @ ${{ commalize(product.price) }}</span>
                </li>
                <li>
                  <strong>PRICE: </strong>
                  <span class="text-right text-success" [ngClass]="{'text-warning': order.status === 'refunded'}">${{ commalize(product.price * product.quantity) }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="order.status==='created'">
        <hr>
        <h5 class="text-warning ml-4">Order Assigned for Delivery</h5>
        <h6 class="ml-5">Next Delivery Date: <span class="text-success">{{
          this.nextDeliveryDay + ', ' + formatDate(nextDeliveryDate(), true) }}</span></h6>
        <!-- <button nbButton ghost status="success">Accept Order as {{ user.first }}</button>
        <button nbButton ghost status="warning">Assign Order for Delivery</button> -->
      </ng-container>
      <hr>
    </ng-container>
    <h2 class="text-center text-success font-weight-light mb-4">Notes</h2>
    <div *ngFor="let note of order.notes">
      <p class="text-right mb-3 pb-2" style="border-bottom: 1px solid #efece9;">
        {{ formatDate(note.createdAt, true) }} at {{ formatTime(note.createdAt) }} by
        <span class="text-success">{{ note.user.name }}</span>
        <button nbButton ghost status="danger"
          class="ml-2" *ngIf="note.user.uid === user.uid"
          (click)="removeNote(note)"
        ><nb-icon icon="trash"></nb-icon></button>
      </p>
      <span class="d-block mb-4 ml-4 text-dark" style="font-size: 18px; white-space: pre-wrap">
        {{ note.note }}
      </span>
      <hr width="88%">
    </div>
    <div class="text-right pl-5">
      <textarea nbInput fullWidth fieldSize="giant" placeholder="Write a note" [(ngModel)]="note" class="mb5"></textarea>
      <button nbButton [disabled]="!note?.trim()" size="small" shape="semi-round" status="success" (click)="addNote()">Add Note</button>
    </div>
  </div>
</div>
