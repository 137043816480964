import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Lead, User } from '@app/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
  ) { }

  getUsers(): Observable<User[]> {
    return this.firestore.collection<User>('users', ref => ref.orderBy('last', 'asc')).valueChanges();
  }

  getLeads(): Observable<Lead[]> {
    return this.firestore.collection<Lead>('leads', ref => ref.orderBy('createdAt', 'desc'))
      .valueChanges({ idField: 'id' });
  }

  getUser(id: string) {
    return this.firestore.collection<User>('users', ref => ref.where('id', '==', id)).valueChanges();
  }

  updateUser(user: User) {
    return this.firestore.collection<User>('users').doc(user.id).update(user);
  }

  uploadAvatar(image: File, name: string) {
    return this.storage.upload('images/user-avatar/' + name, image);
  }

}
