import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

import {
  NbIconModule,
  NbButtonModule,
  NbCardModule,
  NbSpinnerModule,
  NbInputModule,
  NbSelectModule,
  NbToggleModule,
  NbDatepickerModule,
  NbTimepickerModule,
  NbCheckboxModule,
} from '@nebular/theme';
import { ProductComponent } from './product/product.component';
import { OverlayComponent } from './overlay.component';
import { FormsModule } from '@angular/forms';
import { PostComponent } from './post/post.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { OrderComponent } from './order/order.component';
import { LocationsComponent } from '@app/locations/locations.component';
import { StoreLocationComponent } from './location/location.component';
import { GoogleMapsModule } from '@angular/google-maps'
import { PlacesModule } from '../places/places.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  declarations: [
    OverlayComponent,
    ProductComponent,
    PostComponent,
    OrderComponent,
    StoreLocationComponent
  ],
  imports: [
    CommonModule,
    NbIconModule,
    NbButtonModule,
    NbCardModule,
    NbSpinnerModule,
    NbCheckboxModule,
    NbInputModule,
    NbSelectModule,
    NbToggleModule,
    FormsModule,
    EditorModule,
    DragDropModule,
    NbDatepickerModule,
    NbTimepickerModule,
    GoogleMapsModule,
    PlacesModule,
    PdfViewerModule,
  ],
  exports: [
    OverlayComponent
  ]
})
export class OverlaysModule { }
