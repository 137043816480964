<div class="row">
    <div class="col-md-8 pr0">
        <nb-card>
            <nb-card-body>
                <input #productTitle class="product-title" type="text" nbInput fullWidth fieldSize="giant"
                    [status]="productTitle.value.trim()?'success':'danger'"
                    placeholder="Product Title (required)"
                    [(ngModel)]="product.title">
                <table width="100%" class="product-title">
                  <tr>
                    <td class="pr-3">
                      <input disabled type="text" nbInput fullWidth fieldSize="small"
                          placeholder="Product Slug (required)"
                          [(ngModel)]="product.slug">
                    </td>
                    <td width="50px">
                      <input type="number" nbInput fieldSize="small" placeholder="Product Index" [(ngModel)]="product.index">
                    </td>
                  </tr>
                </table>
                <div class="row">
                    <div class="col-md-6 pr0">
                        <nb-select filled fullWidth placeholder="Product Brand"
                            [(selected)]="selectBrand" [(ngModel)]="product.brand">
                            <nb-select-label>
                                Brand: {{ brandSelected }}
                            </nb-select-label>
                            <nb-option *ngFor="let brand of brands" [value]="brand.value">{{ brand.title }}</nb-option>
                        </nb-select>
                    </div>
                    <div class="col-md-6">
                        <nb-select filled fullWidth placeholder="Product Variety"
                            [(selected)]="selectVariety" [(ngModel)]="product.variety">
                            <nb-select-label>
                                Variety: {{ varietySelected }}
                            </nb-select-label>
                            <nb-option *ngFor="let variety of varieties" [value]="variety.value">{{ variety.title }}</nb-option>
                        </nb-select>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>
        <nb-card>
            <nb-card-body>
                <textarea nbInput fullWidth fieldSize="giant" placeholder="Product Summary" [(ngModel)]="product.story" class="mb5"></textarea>
                <textarea nbInput fullWidth placeholder="Product Description" class="mb5"
                    [(ngModel)]="product.description[i]"
                    *ngFor="let desc of product?.description; index as i;"></textarea>
                <button class="block-center" nbButton size="small" outline status="warning"
                    (click)="addDescription()">Add Description</button>
            </nb-card-body>
        </nb-card>
    </div>
    <div class="col-md-4">
        <nb-card class="flex-center">
            <nb-card-body>
                <div class="card-title">Product Likes</div>
                <h2 class="text-center" [ngClass]="{'text-success': product.likes}">{{product.likes || 0}}</h2>
            </nb-card-body>
        </nb-card>
        <nb-flip-card [flipped]="editIcon" [showToggleButton]="false">
            <nb-card-front>
                <nb-card>
                    <nb-card-body>
                        <ng-container *ngIf="!!product?.icon||iconDeleted; else noIcon">
                            <img class="product-image" [src]="product.icon" *ngIf="!!product?.icon;" alt="">
                            <div class="deleted-images single" *ngIf="!!iconDeleted">
                                <div class="p-1">
                                    <img class="deleted-image" [src]="iconDeleted">
                                    <button nbButton size="tiny" outline status="warning" class="mt-3 block-center delete"
                                        (click)="restoreIcon()">Restore</button>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #noIcon>
                            <div class="no-icon">
                                <nb-icon icon="image-outline"></nb-icon>
                                <span class="message">
                                    There is no icon for this product{{ product.public ?
                                        ', yet it is visible. Please add an icon.' :
                                        '. Please add one before making this product visible.' }}
                                </span>
                                <button nbButton size="small" outline status="warning" (click)="editIcon=true">Add Icon</button>
                            </div>
                        </ng-template>
                    </nb-card-body>
                    <nb-card-footer *ngIf="!!product?.icon||iconDeleted">
                        <div style="justify-content: center; display: flex;">
                            <button nbButton size="small" outline status="warning" class="m-auto"
                                (click)="editIcon=true">{{ iconDeleted ? 'Add Icon' : 'Update' }}</button>
                            <button nbButton size="small" outline status="danger" class="m-auto"
                                *ngIf="!iconDeleted"
                                (click)="deleteIcon()">Delete</button>
                        </div>
                    </nb-card-footer>
                </nb-card>
            </nb-card-front>
            <nb-card-back>
                <nb-card>
                    <nb-card-body>
                        <input hidden type="file" #iconInput accept="image/*" (change)="addIcon($event.target.files)">
                        <ng-container *ngIf="iconInfo.length; else selectIconFile">
                            <ng-container *ngIf="uploadingIcon; else previewIcon">
                                <h3 class="text-center">
                                    Uploading product icon...
                                    <strong>{{ iconUpload | async }}%</strong>
                                </h3>
                            </ng-container>
                            <ng-template #previewIcon>
                                <div class="files-list" *ngFor="let info of iconInfo;let i= index">
                                    <img class="product-image" [src]="info.preview">
                                    <button nbButton status="warning" class="block-center mt-2" (click)="removeIcon(iconInput)">
                                        <nb-icon icon="close-square-outline"></nb-icon>
                                    </button>
                                    <hr>
                                </div>
                            </ng-template>
                        </ng-container>
                        <ng-template #selectIconFile>
                            <div class="no-icon">
                                <nb-icon icon="image-outline"></nb-icon>
                                <span class="message">{{ this.product.icon ? 'Update' : 'Add' }} Product Icon</span>
                                <button nbButton size="small" outline status="warning" (click)="iconInput.click()">
                                    Select File
                                </button>
                            </div>
                        </ng-template>
                        <button nbButton size="small" outline status="danger" class="mt-3 block-center"
                            (click)="editIcon=false">Cancel</button>
                    </nb-card-body>
                </nb-card>
            </nb-card-back>
        </nb-flip-card>
        <nb-flip-card [flipped]="editPoster" [showToggleButton]="false">
            <nb-card-front>
                <nb-card>
                    <nb-card-body>
                        <ng-container *ngIf="!!product?.poster||posterDeleted; else noPoster">
                            <img class="product-image" [src]="product.poster" *ngIf="!!product?.poster;" alt="">
                            <div class="deleted-images single" *ngIf="!!posterDeleted">
                                <div class="p-1">
                                    <img class="deleted-image" [src]="posterDeleted">
                                    <button nbButton size="tiny" outline status="warning" class="mt-3 block-center delete"
                                        (click)="restorePoster()">Restore</button>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #noPoster>
                            <div class="no-poster">
                                <nb-icon icon="image-outline"></nb-icon>
                                <span class="message">
                                    There is no poster for this product{{ product.public ?
                                        ', yet it is visible. Please add a poster.' :
                                        '. Please add one before making this product visible.' }}
                                </span>
                                <button nbButton size="small" outline status="warning" (click)="editPoster=true">Add Poster</button>
                            </div>
                        </ng-template>
                    </nb-card-body>
                    <nb-card-footer *ngIf="!!product?.poster||posterDeleted">
                        <div style="justify-content: center; display: flex;">
                            <button nbButton size="small" outline status="warning" class="m-auto"
                                (click)="editPoster=true">{{ posterDeleted ? 'Add Poster' : 'Update' }}</button>
                            <button nbButton size="small" outline status="danger" class="m-auto"
                                *ngIf="!posterDeleted"
                                (click)="deletePoster()">Delete</button>
                        </div>
                    </nb-card-footer>
                </nb-card>
            </nb-card-front>
            <nb-card-back>
                <nb-card>
                    <nb-card-body>
                        <input hidden type="file" #posterInput accept="image/*" (change)="addPoster($event.target.files)">
                        <ng-container *ngIf="posterInfo.length; else selectPosterFile">
                            <ng-container *ngIf="uploadingPoster; else previewPoster">
                                <h3 class="text-center">
                                    Uploading product poster...
                                    <strong>{{ posterUpload | async }}%</strong>
                                </h3>
                            </ng-container>
                            <ng-template #previewPoster>
                                <div class="files-list" *ngFor="let info of posterInfo;let i= index">
                                    <img class="product-image" [src]="info.preview">
                                    <button nbButton status="warning" class="block-center mt-2" (click)="removePoster(posterInput)">
                                        <nb-icon icon="close-square-outline"></nb-icon>
                                    </button>
                                    <hr>
                                </div>
                            </ng-template>
                        </ng-container>
                        <ng-template #selectPosterFile>
                            <div class="no-poster">
                                <nb-icon icon="image-outline"></nb-icon>
                                <span class="message">{{ this.product.poster ? 'Update' : 'Add' }} Product Poster</span>
                                <button nbButton size="small" outline status="warning" (click)="posterInput.click()">
                                    Select File
                                </button>
                            </div>
                        </ng-template>
                        <button nbButton size="small" outline status="danger" class="mt-3 block-center"
                            (click)="editPoster=false">Cancel</button>
                    </nb-card-body>
                </nb-card>
            </nb-card-back>
        </nb-flip-card>
        <nb-flip-card [flipped]="editFacts" [showToggleButton]="false">
          <nb-card-front>
              <nb-card>
                  <nb-card-body>
                      <ng-container *ngIf="!!product?.facts||factsDeleted; else noFacts">
                          <img class="product-image" [src]="product.facts" *ngIf="!!product?.facts;" alt="">
                          <div class="deleted-images single" *ngIf="!!factsDeleted">
                              <div class="p-1">
                                  <img class="deleted-image" [src]="factsDeleted">
                                  <button nbButton size="tiny" outline status="warning" class="mt-3 block-center delete"
                                      (click)="restoreFacts()">Restore</button>
                              </div>
                          </div>
                      </ng-container>
                      <ng-template #noFacts>
                          <div class="no-facts">
                              <nb-icon icon="image-outline"></nb-icon>
                              <span class="message">
                                  There is no nutritional facts label for this product{{ product.public ?
                                      ', yet it is visible. Please add a facts label.' :
                                      '. Please add one before making this product visible.' }}
                              </span>
                              <button nbButton size="small" outline status="warning" (click)="editFacts=true">Add Facts</button>
                          </div>
                      </ng-template>
                  </nb-card-body>
                  <nb-card-footer *ngIf="!!product?.facts||factsDeleted">
                      <div style="justify-content: center; display: flex;">
                          <button nbButton size="small" outline status="warning" class="m-auto"
                              (click)="editFacts=true">{{ factsDeleted ? 'Add Facts' : 'Update' }}</button>
                          <button nbButton size="small" outline status="danger" class="m-auto"
                              *ngIf="!factsDeleted"
                              (click)="deleteFacts()">Delete</button>
                      </div>
                  </nb-card-footer>
              </nb-card>
          </nb-card-front>
          <nb-card-back>
              <nb-card>
                  <nb-card-body>
                      <input hidden type="file" #factsInput accept="image/*" (change)="addFacts($event.target.files)">
                      <ng-container *ngIf="factsInfo.length; else selectFactsFile">
                          <ng-container *ngIf="uploadingFacts; else previewFacts">
                              <h3 class="text-center">
                                  Uploading product facts...
                                  <strong>{{ factsUpload | async }}%</strong>
                              </h3>
                          </ng-container>
                          <ng-template #previewFacts>
                              <div class="files-list" *ngFor="let info of factsInfo;let i= index">
                                  <img class="product-image" [src]="info.preview">
                                  <button nbButton status="warning" class="block-center mt-2" (click)="removeFacts(factsInput)">
                                      <nb-icon icon="close-square-outline"></nb-icon>
                                  </button>
                                  <hr>
                              </div>
                          </ng-template>
                      </ng-container>
                      <ng-template #selectFactsFile>
                          <div class="no-facts">
                              <nb-icon icon="image-outline"></nb-icon>
                              <span class="message">{{ this.product.facts ? 'Update' : 'Add' }} Product Facts</span>
                              <button nbButton size="small" outline status="warning" (click)="factsInput.click()">
                                  Select File
                              </button>
                          </div>
                      </ng-template>
                      <button nbButton size="small" outline status="danger" class="mt-3 block-center"
                          (click)="editFacts=false">Cancel</button>
                  </nb-card-body>
              </nb-card>
          </nb-card-back>
      </nb-flip-card>
    </div>
</div>
<div class="row">
    <div class="col-xl-7 pr0 m-auto">
        <nb-card>
            <nb-card-body>
                <div class="card-title">Product Images</div>
                <input hidden type="file" #imageInput multiple accept="image/*"
                    (change)="addImage($event.target.files)">
                <div *ngIf="imagesDeleted.length">
                    <div class="deleted-images">
                        <div class="p-1" *ngFor="let image of imagesDeleted">
                            <img class="deleted-image" [src]="image">
                            <button nbButton size="tiny" outline status="warning" class="mt-3 block-center delete"
                                (click)="restoreImage(image)">Restore</button>
                        </div>
                    </div>
                    <hr>
                </div>
                <ng-container *ngIf="product?.images?.length; else noImages">
                    <div class="images-list" [ngClass]="{'double': product.images.length < 3}">
                        <div class="p-1" *ngFor="let image of product.images">
                            <img class="product-image" [src]="image">
                            <button nbButton size="small" outline status="_" class="mt-3 block-center delete"
                                (click)="replaceImage(image)">Delete</button>
                        </div>
                    </div>
                </ng-container>
                <ng-template #noImages>
                    <h6 class="describe-empty">No product images uploaded.</h6>
                </ng-template>
                <hr>
                <ng-container *ngIf="uploadingImages; else previewImages">
                    <h3 class="text-center">
                        Uploading product images...
                    </h3>
                </ng-container>
                <ng-template #previewImages>
                    <div class="images-list mb10">
                        <div class="p-1" *ngFor="let image of imageInfo;">
                            <img class="small product-image" [src]="image.preview">
                            <button nbButton status="warning" class="block-center mt-2" (click)="removeImage(image)">
                                <nb-icon icon="close-square-outline"></nb-icon>
                            </button>
                        </div>
                    </div>
                </ng-template>
                <button class="block-center" nbButton size="small" outline status="warning"
                    (click)="imageInput.click()">Add Images</button>
            </nb-card-body>
        </nb-card>
    </div>
    <div class="col-xl-5 col-md-8 m-auto">
        <nb-card>
            <nb-card-body>
                <div class="card-title">Product Hero</div>
                <ng-container *ngIf="heroList.length; else noHero">
                    <ng-container *ngIf="editHero===false; else heroEdit">
                        <div class="hero-list" [ngClass]="{'single': heroList.length === 1}">
                            <div class="p-1" *ngFor="let hero of heroList;let i= index">
                                <img class="product-image hero" [src]="hero.url">
                                <h6 class="text-center mt-1" style="color: #c5a694; font-weight: 300;">{{hero.size}}</h6>
                            </div>
                        </div>
                        <hr>
                        <button class="block-center" nbButton size="small" outline status="warning"
                            (click)="editHero=true">Update Hero</button>
                    </ng-container>
                </ng-container>
                <ng-template #noHero>
                    <ng-container *ngIf="editHero===false; else heroEdit">
                        <h6 class="describe-empty">No hero images uploaded.</h6>
                        <button class="block-center" nbButton size="small" outline status="warning"
                        (click)="editHero=true">Add Hero</button>
                    </ng-container>
                </ng-template>
                <input hidden type="file" #heroMobileInput accept="image/*" (change)="addHero('mobile', $event.target.files)">
                <input hidden type="file" #heroMediumInput accept="image/*" (change)="addHero('medium', $event.target.files)">
                <input hidden type="file" #heroLargeInput accept="image/*" (change)="addHero('large', $event.target.files)">
                <input hidden type="file" #heroFullInput accept="image/*" (change)="addHero('full', $event.target.files)">
                <ng-template #heroEdit>
                    <ng-container *ngIf="uploadingHero; else previewHero">
                        <h4 class="text-center">Uploading hero images...</h4>
                        <!-- <h2 class="text-right" *ngIf="heroUpload.mobile">mobile: <strong>{{ heroUpload.mobile | async }}%</strong></h2>
                        <h2 class="text-right" *ngIf="heroUpload.medium">medium: <strong>{{ heroUpload.medium | async }}%</strong></h2>
                        <h2 class="text-right" *ngIf="heroUpload.large">large: <strong>{{ heroUpload.large | async }}%</strong></h2>
                        <h2 class="text-right" *ngIf="heroUpload.full">full: <strong>{{ heroUpload.full | async }}%</strong></h2> -->
                    </ng-container>
                    <ng-template #previewHero>
                        <div *ngIf="herosDeleted.length">
                            <div class="deleted-images">
                                <div class="p-1" *ngIf="heroReplaced.full">
                                    <img class="deleted-image" [src]="heroReplaced.full">
                                    <button nbButton size="tiny" outline status="warning" class="mt-3 block-center delete"
                                        (click)="restoreHero(false, 'full', heroFullInput)">Restore Full</button>
                                </div>
                                <div class="p-1" *ngIf="heroReplaced.large">
                                    <img class="deleted-image" [src]="heroReplaced.large">
                                    <button nbButton size="tiny" outline status="warning" class="mt-3 block-center delete"
                                        (click)="restoreHero(false, 'large', heroLargeInput)">Restore Large</button>
                                </div>
                                <div class="p-1" *ngIf="heroReplaced.medium">
                                    <img class="deleted-image" [src]="heroReplaced.medium">
                                    <button nbButton size="tiny" outline status="warning" class="mt-3 block-center delete"
                                        (click)="restoreHero(false, 'medium', heroMediumInput)">Restore Medium</button>
                                </div>
                                <div class="p-1" *ngIf="heroReplaced.mobile">
                                    <img class="deleted-image" [src]="heroReplaced.mobile">
                                    <button nbButton size="tiny" outline status="warning" class="mt-3 block-center delete"
                                        (click)="restoreHero(false, 'mobile', heroMobileInput)">Restore Mobile</button>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <button class="mb-2 block-center" nbButton size="small" outline status="warning"
                            *ngIf="!product.hero?.mobile&&!hero.mobile"
                            (click)="heroMobileInput.click()">Select Mobile Hero</button>
                        <button class="mb-2 block-center" nbButton size="medium" outline status="warning"
                            *ngIf="!product.hero?.medium&&!hero.medium"
                            (click)="heroMediumInput.click()">Select Medium Hero</button>
                        <button class="mb-2 block-center" nbButton size="large" outline status="warning"
                            *ngIf="!product.hero?.large&&!hero.large"
                            (click)="heroLargeInput.click()">Select Large Hero</button>
                        <button class="mb-2 block-center" nbButton size="giant" outline status="warning"
                            *ngIf="!product.hero?.full&&!hero.full"
                            (click)="heroFullInput.click()">Select Full Hero</button>
                        <button class="mb-2 block-center" nbButton size="small" outline status="danger"
                            *ngIf="product.hero?.mobile"
                            (click)="replaceHero('mobile')">Remove Mobile Hero</button>
                        <button class="mb-2 block-center" nbButton size="medium" outline status="danger"
                            *ngIf="product.hero?.medium"
                            (click)="replaceHero('medium')">Remove Medium Hero</button>
                        <button class="mb-2 block-center" nbButton size="large" outline status="danger"
                            *ngIf="product.hero?.large"
                            (click)="replaceHero('large')">Remove Large Hero</button>
                        <button class="mb-2 block-center" nbButton size="giant" outline status="danger"
                            *ngIf="product.hero?.full"
                            (click)="replaceHero('full')">Remove Full Hero</button>
                        <div class="hero-list" *ngIf="heroInfo.length" [ngClass]="{'single': heroInfo.length === 1}">
                            <div class="p-1" *ngFor="let hero of heroInfo;let i= index">
                                <img class="product-image" [src]="hero.preview">
                                <h6 class="text-center mt-1" style="color: #c5a694">{{hero.size}}</h6>
                                <button nbButton status="warning" class="block-center mt-2" (click)="removeHero((
                                    hero.size === 'mobile' ? heroMobileInput :
                                    hero.size === 'medium' ? heroMediumInput :
                                    hero.size === 'large' ? heroLargeInput : heroFullInput), hero.size)">
                                    <nb-icon icon="close-square-outline"></nb-icon>
                                </button>
                            </div>
                        </div>
                        <hr>
                        <button class="block-center" nbButton size="small" outline status="danger"
                            (click)="restoreHero({
                                mobile: heroMobileInput,
                                medium: heroMediumInput,
                                large: heroLargeInput,
                                full: heroFullInput
                            });editHero=false">Cancel</button>
                    </ng-template>
                </ng-template>
            </nb-card-body>
        </nb-card>
    </div>
</div>
<div class="row">
    <div class="col-md-4 pr0">
        <nb-card>
            <nb-card-body>
                <div class="card-title">Ingredients</div>
                <ng-container *ngIf="product?.ingredients">
                    <textarea nbInput fullWidth placeholder="Main Ingredients" class="mb5"
                        [(ngModel)]="product.ingredients.main"></textarea>
                    <input nbInput fullWidth fieldSize="small" status="warning" placeholder="Ingredient Warning" class="mb10"
                        [(ngModel)]="product.ingredients.warning">
                    <input nbInput fullWidth placeholder="Ingredients Footnote" class="mb10"
                        [(ngModel)]="product.ingredients.footnotes[i]"
                        *ngFor="let desc of product?.ingredients?.footnotes; index as i;">
                    <button class="block-center" nbButton size="small" outline status="warning"
                        (click)="addFootnote()">Add Footnote</button>
                </ng-container>
            </nb-card-body>
        </nb-card>
    </div>
    <div class="col-md-4 pr0">
        <nb-card>
            <nb-card-body>
                <div class="card-title">Sizes</div>
                <ng-container *ngIf="product?.sizes?.length; else noSizes">
                    <input nbInput fullWidth fieldSize="large" placeholder="Size" class="mb10"
                        [(ngModel)]="product.sizes[i]"
                        *ngFor="let size of sizes; index as i;">
                </ng-container>
                <ng-template #noSizes>
                    <h6 class="describe-empty">No sizes specified.</h6>
                </ng-template>
                <button class="block-center" nbButton size="small" outline status="warning"
                    (click)="addSize()">Add Size</button>
            </nb-card-body>
        </nb-card>
    </div>
    <div class="col-md-4">
        <nb-card>
            <nb-card-body>
                <div class="card-title">Price{{ sizePerPrice?.length > 1 ? 's' : '' }}</div>
                    <ng-container *ngIf="sizePerPrice?.length;else staticPrice">
                      <h6 class="text-center text-success text-uppercase">Retail</h6>
                      <input type="number" min="5" step="0.01" nbInput fullWidth fieldSize="giant" status="success" [placeholder]="'Retail Price per ' + size" class="mb10"
                        [(ngModel)]="product.price[size]"
                        *ngFor="let size of sizePerPrice">
                      <hr>
                      <h6 class="text-center text-success text-uppercase">Wholesale</h6>
                      <input type="number" min="5" step="0.01" nbInput fullWidth fieldSize="large" status="success" [placeholder]="'Wholesale Price per ' + size" class="mb10"
                        [(ngModel)]="product.wholesale[size]"
                        *ngFor="let size of sizePerPrice">
                    </ng-container>
                    <ng-template #staticPrice>
                      <input type="number" min="5" step="0.01" nbInput fullWidth fieldSize="giant" status="success" placeholder="Product Price Retail"
                        [(ngModel)]="price">
                      <hr>
                      <input type="number" min="5" step="0.01" nbInput fullWidth fieldSize="giant" status="success" placeholder="Product Price Wholesale"
                        [(ngModel)]="wholesale">
                    </ng-template>
            </nb-card-body>
        </nb-card>
        <nb-card class="inline mr-3">
            <nb-card-body>
                <div class="card-title">Visibility</div>
                <nb-toggle labelPosition="right"
                    [(ngModel)]="product.public"
                    [status]="product.public?'success':'warning'">
                    Product {{ product.public ? 'Visible' : 'Concealed' }}
                </nb-toggle>
            </nb-card-body>
        </nb-card>
        <nb-card class="inline">
            <nb-card-body>
                <div class="card-title">Featured</div>
                <nb-toggle labelPosition="right"
                    [(ngModel)]="product.featured"
                    [status]="product.featured?'success':'warning'">
                    Product {{ product.featured ? 'Featured' : 'Not Featured' }}
                </nb-toggle>
            </nb-card-body>
        </nb-card>
    </div>
</div>
<button class="block-center" nbButton size="giant" outline status="success" style="margin-bottom: 10px;"
    (click)="saveProduct()">Save</button>
<button class="block-center save" nbButton size="medium" outline status="_" style="margin-bottom: 10px;"
    (click)="saveProduct(true)">Save & Close</button>
