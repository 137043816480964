<div class="row">
    <div class="col-md-6 pr0">
        <nb-card>
            <nb-card-body>
                <input #postTitle class="mb10" type="text" nbInput fullWidth fieldSize="giant"
                    [status]="postTitle.classList.contains('ng-pristine') ||
                      postTitle.value.trim()?'success':'danger'"
                    placeholder="Post Title (required)"
                    [(ngModel)]="post.title">
                <input #postPage class="mb10" type="text" nbInput fullWidth fieldSize="medium"
                    [status]="postPage.classList.contains('ng-pristine') ||
                      postPage.value.trim()?'success':'danger'"
                    placeholder="Page Title (required)"
                    (keyup)="do($event, postTitle)"
                    (ngModelChange)="updatePostSlug($event)"
                    [(ngModel)]="post.page">
                  <div class="row">
                    <div class="col-sm-6 mb-2 mb-sm-0 pr0">
                      <input disabled type="text" nbInput fullWidth fieldSize="small"
                      placeholder="Page Slug"
                      [(ngModel)]="post.slug">
                    </div>
                    <div class="col-sm-6">
                      <nb-select filled fullWidth size="small" placeholder="Post Type" [(ngModel)]="post.type">
                        <nb-select-label>
                          Type: {{ postType[post?.type || 'post'] }}
                        </nb-select-label>
                        <nb-option class="text-capitalize" *ngFor="let type of types" [value]="type">{{ postType[type] }}</nb-option>
                      </nb-select>
                    </div>
                  </div>
            </nb-card-body>
        </nb-card>
        <div class="row">
          <div class="col-lg-4 col-sm-7 m-auto" *ngIf="createPost; else postStatus" [ngClass]="{pr0: schedulePost}">
            <nb-card>
                <nb-card-body class="text-center">
                  <nb-toggle labelPosition="right"
                    [(ngModel)]="schedulePost"
                    [status]="schedulePost?'success':'warning'">
                    Schedule{{ schedulePost ? 'd' : '' }} Post
                  </nb-toggle>
                </nb-card-body>
            </nb-card>
          </div>
          <div class="col-lg-4 col-sm-6 pr0" *ngIf="createPost&&schedulePost">
            <nb-card>
              <nb-card-body>
                <input class="text-center" nbInput
                  [(ngModel)]="dateScheduled"
                  placeholder="Select Post Date"
                  [nbDatepicker]="dateTimePicker" style="width: 100%;">
                <nb-datepicker #dateTimePicker [min]="newDate()"></nb-datepicker>
              </nb-card-body>
            </nb-card>
          </div>
          <div class="col-lg-4 col-sm-6" *ngIf="createPost&&schedulePost">
            <nb-card>
              <nb-card-body>
                <input class="text-center" nbInput
                  [(ngModel)]="timeScheduled"
                  placeholder="Select a Time"
                  [nbTimepicker]="timepicker" twelveHoursFormat style="width: 100%;">
                <nb-timepicker #timepicker twelveHoursFormat withSeconds></nb-timepicker>
              </nb-card-body>
            </nb-card>
          </div>
          <ng-template #postStatus>
            <div class="col-sm-7 m-auto" [ngClass]="{'col-lg-5': post.status!=='scheduled', 'col-lg-4': post.status==='scheduled'}">
              <nb-card>
                  <nb-card-body>
                    <nb-select filled fullWidth placeholder="Post Status" [(ngModel)]="post.status">
                        <nb-select-label>
                            Status: {{ post?.status?.toUpperCase() }}
                        </nb-select-label>
                        <nb-option class="text-capitalize" *ngFor="let status of statuses" [value]="status">{{ status }}</nb-option>
                    </nb-select>
                  </nb-card-body>
              </nb-card>
            </div>
            <ng-container *ngIf="post.status==='scheduled'">
              <div class="col-lg-4 col-sm-6 pr0">
                <nb-card>
                  <nb-card-body>
                    <input class="text-center" nbInput
                      [ngModel]="post.date"
                      placeholder="Select Post Date"
                      [nbDatepicker]="dateTimePicker" style="width: 100%;">
                    <nb-datepicker #dateTimePicker></nb-datepicker>
                  </nb-card-body>
                </nb-card>
              </div>
              <div class="col-lg-4 col-sm-6">
                <nb-card>
                  <nb-card-body>
                    <input class="text-center" nbInput
                      [ngModel]="timeScheduled"
                      placeholder="Select a Time"
                      [nbTimepicker]="timepicker" twelveHoursFormat style="width: 100%;">
                    <nb-timepicker #timepicker twelveHoursFormat withSeconds></nb-timepicker>
                  </nb-card-body>
                </nb-card>
              </div>
            </ng-container>
          </ng-template>
        </div>
    </div>
    <div class="col-md-6">
      <nb-card class="post-hero" [ngClass]="{removed: post.heroRemoved || editHero}" [ngStyle]="{
        background: !!post?.hero&&!editHero?'url('+post.hero+') ' + (post.heroAlign || 'center') + '/cover no-repeat':''}">
        <input hidden type="file" #heroInput accept="image/*" (change)="addHero($event.target.files)">
        <nb-card-body *ngIf="!post.heroRemoved">
            <div class="card-title text-center font-weight-bold" [ngClass]="{'text-light': !editHero}">Post Hero</div>
        </nb-card-body>
        <nb-card-footer>
          <ng-container *ngIf="!!post?.hero||heroDeleted||!post?.heroRemoved; else noHero">
            <!-- <img class="post-image" [src]="post.hero" *ngIf="!!post?.hero;" alt=""> -->
            <div class="deleted-images single" *ngIf="!!heroDeleted">
                <div class="p-1">
                    <img class="deleted-image" [src]="heroDeleted">
                    <button nbButton size="tiny" outline status="warning" class="mt-3 block-center delete"
                        (click)="retoreHero()">Restore</button>
                </div>
                <hr>
            </div>
            <ng-container *ngIf="!editHero; else updateHero">
              <p class="hint text-success">This background image will be featured at the top of the post. </p>
              <div class="text-center">
                <div class="form-check form-check-inline">
                  <input [(ngModel)]="post.heroAlign" class="form-check-input" type="radio" name="inlineRadioOptions" id="align-top" value="top">
                  <label class="form-check-label" for="align-top">Align Top</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [(ngModel)]="post.heroAlign" class="form-check-input" type="radio" name="inlineRadioOptions" id="align-center" value="center">
                  <label class="form-check-label" for="align-center">Align Middle</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [(ngModel)]="post.heroAlign" class="form-check-input" type="radio" name="inlineRadioOptions" id="align-bottom" value="bottom">
                  <label class="form-check-label" for="align-bottom">Align Bottom</label>
                </div>
              </div>
              <hr>
              <div class="text-center">
                <button class="mb-1 mr-2" nbButton size="small" outline status="warning"
                    (click)="editHero=true">Update Hero</button>
                <button class="mb-1" nbButton size="small" outline status="danger"
                    (click)="removeHero()">Remove {{ post.hero ? 'Hero' : 'Default' }}</button>
              </div>
            </ng-container>
        </ng-container>
        <ng-template #noHero>
          <ng-container *ngIf="!editHero; else updateHero">
            <div class="no-hero">
                <nb-icon icon="image-outline"></nb-icon>
                <p class="hint text-success">There is no hero image for this post.</p>
                <span class="message">
                  The "hero" is a background image that will be featured at the top of the post.
                  Adding a hero image is great for the look and feel of a post.</span>
                <button nbButton size="small" outline status="warning" (click)="selectHero(heroInput)" class="mb-1">Add Hero</button>
                <button nbButton size="small" outline status="success" (click)="post.heroRemoved=null">Restore Default</button>
            </div>
          </ng-container>
        </ng-template>
        <ng-template #updateHero>
          <ng-container *ngIf="heroInfo.length; else selectHero">
              <ng-container *ngIf="uploadingHero; else previewHero">
                  <h3 class="text-center">
                      Uploading post hero...
                      <strong>{{ heroUpload | async }}%</strong>
                  </h3>
              </ng-container>
              <ng-template #previewHero>
                  <div class="files-list" *ngFor="let info of heroInfo;let i= index">
                      <img class="hero-image" [src]="info.preview">
                      <button nbButton status="warning" class="block-center mt-2" (click)="removeHero(heroInput)">
                          <nb-icon icon="close-square-outline"></nb-icon>
                      </button>
                      <hr>
                  </div>
              </ng-template>
          </ng-container>
          <ng-template #selectHero>
              <div class="no-hero">
                  <nb-icon icon="image-outline"></nb-icon>
                  <span class="message">{{ post.hero || !post.heroRemoved ? 'Update' : 'Add' }} Post Hero</span>
                  <button nbButton size="small" outline status="warning" (click)="heroInput.click()">
                      Select File
                  </button>
              </div>
          </ng-template>
          <button nbButton size="small" outline status="danger" class="mt-3 block-center"
              (click)="retoreHero()">Cancel</button>
        </ng-template>
        </nb-card-footer>
      </nb-card>
    </div>
</div>
<hr class="mb-1">
<div class="row post-content">
  <!-- <editor
  [init]="{
     base_url: '/tinymce',
     suffix: '.min',
     height: 500,
     menubar: false,
     plugins: [
       'advlist autolink lists link image charmap print preview anchor',
       'searchreplace visualblocks code fullscreen',
       'insertdatetime media table paste code help wordcount'
     ],
     toolbar:
       'undo redo | formatselect | bold italic backcolor | \
       alignleft aligncenter alignright alignjustify | \
       bullist numlist outdent indent | removeformat | help'
   }"
 ></editor> -->
 <nb-card class="mb-1">
   <nb-card-body>
     <div class="featured row">
       <div class="col-md-6 col-sm-7 pr0 m-auto">
         <nb-card>
           <nb-card-body>
             <ng-container *ngIf="!!post?.content.featured.image||featuredDeleted; else noFeatured">
                <img class="featured-image" [src]="post.content.featured.image" [ngClass]="{shrink: editFeatured}"
                  *ngIf="!!post?.content.featured.image&&!featuredDeleted" alt="">
                <hr>
                <div class="deleted-images single" *ngIf="!!featuredDeleted">
                    <div class="p-1">
                        <img class="deleted-image" [src]="featuredDeleted">
                        <button nbButton size="tiny" outline status="warning" class="mt-3 block-center delete"
                            (click)="retoreFeatured()">Restore</button>
                    </div>
                    <hr>
                </div>
                <ng-container *ngIf="!editFeatured; else updateFeatured">
                  <p class="hint text-success" *ngIf="!featuredDeleted">This image will be featured at the top of the post. </p>
                  <div class="mb-4 text-center">
                    <nb-checkbox
                      [indeterminate]="!post.content.featured.transparent"
                      [(ngModel)]="post.content.featured.transparent">Borderless</nb-checkbox>
                  </div>
                  <div class="text-center">
                    <button class="mb-1 mr-2" nbButton size="small" outline status="warning"
                        (click)="selectFeatured(featuredInput)"><nb-icon icon="image-outline"></nb-icon>{{ featuredDeleted ? 'Select' : 'Update'}} Featured Image</button>
                    <button class="mb-1" nbButton size="small" outline status="danger" *ngIf="!featuredDeleted"
                        (click)="deleteFeatured()">Remove Featured Image</button>
                  </div>
                </ng-container>
            </ng-container>
            <ng-template #noFeatured>
              <ng-container *ngIf="!editFeatured; else updateFeatured">
                <h6 *ngIf="!createPost" class="describe-empty">No featured image uploaded.</h6>
                <p class="hint">Selecting a featured image is recommended for an optimal user experience.</p>
                <button class="block-center" nbButton size="small" outline status="warning"
                (click)="selectFeatured(featuredInput)"><nb-icon icon="image-outline"></nb-icon>Select Featured Image</button>
              </ng-container>
            </ng-template>
            <ng-template #updateFeatured>
              <ng-container *ngIf="featuredInfo.length; else selectFeatured">
                  <ng-container *ngIf="uploadingFeatured; else previewFeatured">
                      <h3 class="text-center">
                          Uploading featured image...
                          <strong>{{ featuredUpload | async }}%</strong>
                      </h3>
                  </ng-container>
                  <ng-template #previewFeatured>
                      <div class="files-list" *ngFor="let info of featuredInfo;let i= index">
                          <img class="featured-image" [src]="info.preview">
                          <button nbButton status="warning" class="block-center mt-2" (click)="removeFeatured(featuredInput)">
                              <nb-icon icon="close-square-outline"></nb-icon>
                          </button>
                          <hr>
                      </div>
                  </ng-template>
              </ng-container>
              <ng-template #selectFeatured>
                  <div class="no-featured">
                      <nb-icon icon="image-outline"></nb-icon>
                      <span class="message">{{ post.content.featured.image ? 'Update' : 'Add' }} Featured Image</span>
                      <button nbButton size="small" outline status="warning" (click)="featuredInput.click()">
                          Select File
                      </button>
                  </div>
              </ng-template>
              <button nbButton size="small" outline status="danger" class="mt-3 block-center"
                  (click)="retoreFeatured()">Cancel</button>
            </ng-template>
            <input hidden type="file" #featuredInput accept="image/*" (change)="addFeatured($event.target.files)">
           </nb-card-body>
         </nb-card>
        </div>
        <div class="col-md-6 m-auto">
         <nb-card class="featured-p">
           <nb-card-body>
             <ng-container *ngIf="post.content?.featured.paragraph===false; else editFeaturedP">
               <h6 *ngIf="!createPost" class="describe-empty">No featured paragraph.</h6>
               <p class="hint">Setting a featured paragraph can provide useful, high level information about the post.</p>
               <button class="block-center" nbButton size="small" outline status="warning"
               (click)="post.content.featured.paragraph='Enter Text Here'"><nb-icon icon="text"></nb-icon>Set Featured Paragraph</button>
             </ng-container>
             <ng-template #editFeaturedP>
              <editor
                width="100%"
                tagName="p"
                [initialValue]="post.content.featured.paragraph"
                [(ngModel)]="post.content.featured.paragraph"
                [init]="{
                  base_url: '/tinymce', suffix: '.min',
                  menubar: false, inline: true,
                  plugins: ['code anchor link paste', 'emoticons preview'],
                  toolbar: 'undo redo | bold italic underline | link | emoticons | code | preview',
                  valid_elements: 'strong,em,span[style],a[*],button[*]',
                  valid_styles: { '*': 'font-size,font-family,color,text-decoration,text-align' }
                }"
              ></editor>
             </ng-template>
           </nb-card-body>
         </nb-card>
       </div>
     </div>

     <p class="hint text-success mt-3" *ngIf="!post.content.blocks.length">Use the following button to add blocks of content to this post.</p>
     <div cdkDropList (cdkDropListDropped)="drop($event)">
       <nb-card cdkDrag class="pos-r" *ngFor="let block of post.content.blocks; index as i" [class]="block.class">
        <div cdkDragHandle>
          <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
            <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
        <div *cdkDragPlaceholder></div>
        <div class="remove-item block">
          <button class="mr-2" nbButton size="small" status="danger" *ngIf="block.remove"
          (click)="removeContent(i)">Remove</button>
          <nb-icon [ngClass]="{cancel: block.remove}" [icon]="block.remove?'slash':'trash'" (click)="toggleRemove(block)" [status]="block.remove?'success':'warning'"></nb-icon>
        </div>
         <nb-card-body>

          <hr *ngIf="block.tag==='hr'">

          <ng-container *ngIf="block.tag==='h1'||block.tag==='h2'||block.tag==='h3'||block.tag==='h4'">
            <editor width="100%" [tagName]="block.tag" [(ngModel)]="block.html"
              [init]="{
                base_url: '/tinymce',
                suffix: '.min',
                menubar: false,
                inline: true,
                plugins: ['anchor autolink lists paste code emoticons link'],
                toolbar: 'undo redo | bold italic underline | alignleft aligncenter alignright | forecolor emoticons | link | code',
                valid_elements: 'strong,em,span[style],a[*]',
                valid_styles: { '*': 'font-size,font-family,color,text-decoration,text-align' }
              }"
            ></editor>
          </ng-container>

          <ng-container *ngIf="block.tag==='img'">
            <ng-container *ngIf="!block.image; else showImage">
              <ng-container *ngIf="block.imageRemoved">
                <div class="p-1">
                  <img class="deleted-image" [src]="block.imageRemoved">
                  <button nbButton size="tiny" outline status="warning" class="mt-3 block-center delete"
                    (click)="restoreImage(block)">Restore</button>
                </div>
                <hr>
              </ng-container>
              <ng-container *ngIf="block.imgInfo?.length; else showSelectImage">
                <div class="img-content" *ngFor="let info of block.imgInfo;let i= index">
                  <img class="post-image" [src]="info.preview">
                  <button nbButton status="warning" class="block-center mt-2" (click)="removeImage(block)">
                      <nb-icon icon="close-square-outline"></nb-icon>
                  </button>
                </div>
              </ng-container>
              <ng-template #showSelectImage>
                <button class="block-center" nbButton size="giant" outline status="warning"
                  (click)="selectImage(block)"><nb-icon icon="image-outline"></nb-icon>Select Image</button>
                </ng-template>
            </ng-container>
            <ng-template #showImage>
              <img class="block-center" [src]="block.image">
              <hr>
              <button class="block-center" nbButton size="small" outline status="danger"
                (click)="removeImage(block)">Replace Image</button>
            </ng-template>
          </ng-container>

          <ng-container *ngIf="block.tag==='pdf'">
            <ng-container *ngIf="!block.pdf; else showPDF">
              <ng-container *ngIf="block.pdfRemoved">
                <div class="p-1">
                  <pdf-viewer class="deleted-pdf" [src]="block.pdfRemoved"
                    [render-text]="false"
                    [original-size]="false"
                  ></pdf-viewer>
                  <button nbButton size="tiny" outline status="warning" class="mt-3 block-center delete"
                    (click)="restorePDF(block)">Restore</button>
                </div>
                <hr>
              </ng-container>
              <ng-container *ngIf="block.pdfInfo?.length; else showSelectPDF">
                <div class="img-content" *ngFor="let info of block.pdfInfo;let i= index">
                  <pdf-viewer [src]="info.preview"
                    [render-text]="false"
                    [original-size]="true"
                  ></pdf-viewer>
                  <button nbButton status="warning" class="block-center mt-2" (click)="removePDF(block)">
                      <nb-icon icon="close-square-outline"></nb-icon>
                  </button>
                </div>
              </ng-container>
              <ng-template #showSelectPDF>
                <button class="block-center" nbButton size="giant" outline status="warning"
                  (click)="selectPDF(block)"><nb-icon icon="book-open-outline"></nb-icon>Select PDF</button>
                </ng-template>
            </ng-container>
            <ng-template #showPDF>
              <pdf-viewer [src]="block.pdf"
                [render-text]="false"
                [original-size]="true"
              ></pdf-viewer>
              <hr>
              <button class="block-center" nbButton size="small" outline status="danger"
                (click)="removePDF(block)">Replace PDF</button>
            </ng-template>
          </ng-container>

          <ng-container *ngIf="block.tag==='video'">
            <ng-container *ngIf="!block.video; else showVideo">
              <ng-container *ngIf="block.videoRemoved">
                <div class="p-1">
                  <video class="deleted-video" [src]="block.videoRemoved"></video>
                  <button nbButton size="tiny" outline status="warning" class="mt-3 block-center delete"
                    (click)="restoreVideo(block)">Restore</button>
                </div>
                <hr>
              </ng-container>
              <ng-container *ngIf="block.vidInfo?.length; else showSelectVideo">
                <div class="img-content" *ngFor="let info of block.vidInfo;let i= index">
                  <video controls class="post-video" [src]="trustVideo(info.preview)"></video>
                  <button nbButton status="warning" class="block-center mt-2" (click)="removeVideo(block)">
                      <nb-icon icon="close-square-outline"></nb-icon>
                  </button>
                </div>
              </ng-container>
              <ng-template #showSelectVideo>
                <button class="block-center" nbButton size="giant" outline status="warning"
                  (click)="selectVideo(block)"><nb-icon icon="video-outline"></nb-icon>Select Video</button>
                </ng-template>
            </ng-container>
            <ng-template #showVideo>
              <video controls class="block-center" [src]="block.video"></video>
              <hr>
              <button class="block-center" nbButton size="small" outline status="danger"
                (click)="removeVideo(block)">Replace Video</button>
            </ng-template>
          </ng-container>

          <ng-container *ngIf="block.tag==='div'&&block.class.includes('text')">
            <editor width="100%" [tagName]="block.tag" [(ngModel)]="block.html"
              [init]="{
                base_url: '/tinymce',
                suffix: '.min',
                menubar: false,
                inline: true,
                plugins: ['anchor autolink code emoticons link lists paste'],
                toolbar: [
                'undo redo | bold italic underline | fontselect fontsizeselect | emoticons | link | code',
                'forecolor backcolor | alignleft aligncenter alignright alignfull | numlist bullist outdent indent',
                ],
                valid_elements: 'p[style],strong,em,span[style],a[*],ul,ol,li,iframe[*]',
                valid_styles: { '*': 'font-size,font-family,color,text-decoration,text-align,border-radius' }
              }"
            ></editor>
          </ng-container>

          <ng-container *ngIf="block.tag==='div'&&block.class.includes('row')">
            <div class="insert row ml-0 mr-0 mb-3" cdkDropList (cdkDropListDropped)="drop($event, block.content)">
              <nb-card cdkDrag class="pos-r" *ngFor="let column of block.content; index as j" [class]="column.class">
                <ng-container *ngIf="block.content.length>1">
                  <div cdkDragHandle>
                    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                      <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                  </div>
                  <div *cdkDragPlaceholder></div>
                </ng-container>
                <div class="remove-item block" [ngClass]="{'single': block.content?.length===1}">
                  <button class="mr-2" nbButton size="small" status="danger" *ngIf="column.remove"
                  (click)="removeContent(j, block.content)">Remove</button>
                  <nb-icon [ngClass]="{cancel: column.remove}" [icon]="column.remove?'slash':'trash'" (click)="toggleRemove(column)" [status]="column.remove?'success':'warning'"></nb-icon>
                </div>

                <nb-card-body>
                  <ng-container *ngIf="column.tag==='hr'||column.tag==='h1'||column.tag==='h2'||column.tag==='h3'||column.tag==='h4'; else insertCol">
                    <hr *ngIf="column.tag==='hr'; else columnHeading">
                    <ng-template #columnHeading>
                      <editor width="100%" [tagName]="column.tag" [(ngModel)]="column.html"
                        [init]="{
                          base_url: '/tinymce',
                          suffix: '.min',
                          menubar: false,
                          inline: true,
                          plugins: ['anchor autolink lists paste code emoticons link'],
                          toolbar: 'undo redo | bold italic underline | alignleft aligncenter alignright | forecolor emoticons | link | code',
                          valid_elements: 'strong,em,span[style],a[*]',
                          valid_styles: { '*': 'font-size,font-family,color,text-decoration,text-align' }
                        }"
                      ></editor>
                    </ng-template>
                  </ng-container>
                  <ng-template #insertCol>
                    <div class="mb-2" *ngIf="column.content?.length" cdkDropList (cdkDropListDropped)="drop($event, column.content)">
                      <div cdkDrag class="pos-r mb-1" *ngFor="let content of column.content; index as k" [class]="content.class">
                        <hr *ngIf="content.tag==='hr'">
                        <ng-container *ngIf="content.tag==='h1'||content.tag==='h2'||content.tag==='h3'||content.tag==='h4'">
                          <editor width="100%" [tagName]="content.tag" [(ngModel)]="content.html"
                            [init]="{
                              base_url: '/tinymce',
                              suffix: '.min',
                              menubar: false,
                              inline: true,
                              plugins: ['anchor autolink lists paste code emoticons link'],
                              toolbar: 'undo redo | bold italic underline | alignleft aligncenter alignright | forecolor emoticons | link | code',
                              valid_elements: 'strong,em,span[style],a[*]',
                              valid_styles: { '*': 'font-size,font-family,color,text-decoration,text-align' }
                            }"
                          ></editor>
                        </ng-container>
                        <ng-container *ngIf="content.tag==='div'&&content.class.includes('text')">
                          <editor width="100%" [tagName]="content.tag" [(ngModel)]="content.html"
                            [init]="{
                              base_url: '/tinymce',
                              suffix: '.min',
                              menubar: false,
                              inline: true,
                              plugins: ['anchor autolink code emoticons link lists paste'],
                              toolbar: [
                              'undo redo | bold italic underline | fontselect fontsizeselect | emoticons | link | code',
                              'forecolor backcolor | alignleft aligncenter alignright alignfull | numlist bullist outdent indent',
                              ],
                              valid_elements: 'p[style],strong,em,span[style],a[*],ul,ol,li,iframe[*]',
                              valid_styles: { '*': 'font-size,font-family,color,text-decoration,text-align,border-radius' }
                            }"
                          ></editor>
                        </ng-container>
                        <ng-container *ngIf="content.tag==='img'">
                          <ng-container *ngIf="!content.image; else showImage">
                            <ng-container *ngIf="content.imageRemoved">
                              <div class="p-1">
                                <img class="deleted-image" [src]="content.imageRemoved">
                                <button nbButton size="tiny" outline status="warning" class="mt-3 block-center delete"
                                  (click)="restoreImage(content)">Restore</button>
                              </div>
                              <hr>
                            </ng-container>
                            <ng-container *ngIf="content.imgInfo?.length; else showSelectImage">
                              <div class="img-content" *ngFor="let info of content.imgInfo;let i= index">
                                <img class="post-image" [src]="info.preview">
                                <button nbButton status="warning" class="block-center mt-2" (click)="removeImage(content)">
                                    <nb-icon icon="close-square-outline"></nb-icon>
                                </button>
                              </div>
                            </ng-container>
                            <ng-template #showSelectImage>
                              <button class="block-center" nbButton size="giant" outline status="warning"
                                (click)="selectImage(content)"><nb-icon icon="image-outline"></nb-icon>Select Image</button>
                              </ng-template>
                          </ng-container>
                          <ng-template #showImage>
                            <img class="block-center" [src]="content.image">
                            <hr>
                            <button class="block-center" nbButton size="small" outline status="danger"
                              (click)="removeImage(content)">Replace Image</button>
                          </ng-template>
                        </ng-container>
                        <ng-container *ngIf="content.tag==='pdf'">
                          <ng-container *ngIf="!content.pdf; else showPDF">
                            <ng-container *ngIf="content.pdfRemoved">
                              <div class="p-1">
                                <pdf-viewer class="deleted-pdf" [src]="content.pdfRemoved"
                                  [render-text]="false"
                                  [original-size]="false"
                                ></pdf-viewer>
                                <button nbButton size="tiny" outline status="warning" class="mt-3 block-center delete"
                                  (click)="restorePDF(content)">Restore</button>
                              </div>
                              <hr>
                            </ng-container>
                            <ng-container *ngIf="content.pdfInfo?.length; else showSelectPDF">
                              <div class="pdf-content" *ngFor="let info of content.pdfInfo;let i= index">
                                <pdf-viewer [src]="info.preview"
                                  [render-text]="false"
                                  [original-size]="true"
                                ></pdf-viewer>
                                <button nbButton status="warning" class="block-center mt-2" (click)="removePDF(content)">
                                    <nb-icon icon="close-square-outline"></nb-icon>
                                </button>
                              </div>
                            </ng-container>
                            <ng-template #showSelectPDF>
                              <button class="block-center" nbButton size="giant" outline status="warning"
                                (click)="selectPDF(content)"><nb-icon icon="book-open-outline"></nb-icon>Select PDF</button>
                              </ng-template>
                          </ng-container>
                          <ng-template #showPDF>
                            <pdf-viewer [src]="content.pdf"
                              [render-text]="false"
                              [original-size]="true"
                            ></pdf-viewer>
                            <hr>
                            <button class="block-center" nbButton size="small" outline status="danger"
                              (click)="removePDF(content)">Replace PDF</button>
                          </ng-template>
                        </ng-container>
                        <ng-container *ngIf="content.tag==='video'">
                          <ng-container *ngIf="!content.video; else showVideo">
                            <ng-container *ngIf="content.videoRemoved">
                              <div class="p-1">
                                <video class="deleted-video" [src]="content.videoRemoved"></video>
                                <button nbButton size="tiny" outline status="warning" class="mt-3 block-center delete"
                                  (click)="restoreVideo(content)">Restore</button>
                              </div>
                              <hr>
                            </ng-container>
                            <ng-container *ngIf="content.vidInfo?.length; else showSelectVideo">
                              <div class="vid-content" *ngFor="let info of content.vidInfo;let i= index">
                                <video controls class="post-video" [src]="trustVideo(info.preview)"></video>
                                <button nbButton status="warning" class="block-center mt-2" (click)="removeVideo(content)">
                                    <nb-icon icon="close-square-outline"></nb-icon>
                                </button>
                              </div>
                            </ng-container>
                            <ng-template #showSelectVideo>
                              <button class="block-center" nbButton size="giant" outline status="warning"
                                (click)="selectVideo(content)"><nb-icon icon="video-outline"></nb-icon>Select Video</button>
                              </ng-template>
                          </ng-container>
                          <ng-template #showVideo>
                            <video controls class="block-center" [src]="content.video"></video>
                            <hr>
                            <button class="block-center" nbButton size="small" outline status="danger"
                              (click)="removeVideo(content)">Replace Video</button>
                          </ng-template>
                        </ng-container>
                        <div class="inline text-warning" cdkDragHandle [ngClass]="{hidden: column.content.length===1}">
                          <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                            <path d="M0 0h24v24H0z" fill="none"></path>
                          </svg>
                        </div>
                        <div *cdkDragPlaceholder></div>
                        <div class="remove-item mt-1 text-center">
                          <button class="mr-2" nbButton size="small" status="danger" *ngIf="content.remove"
                          (click)="removeContent(k, column.content)">Remove</button>
                          <nb-icon [ngClass]="{cancel: content.remove}" [icon]="content.remove?'slash':'trash'" (click)="toggleRemove(content)" [status]="content.remove?'success':'warning'"></nb-icon>
                        </div>
                      </div>
                    </div>
                    <div class="text-center mb-2" *ngIf="column.addHeading">
                      <button class="mr-1 mb-1" nbButton size="small" status="warning"
                      (click)="addContent('h1', null, column)">Heading 1</button>
                      <button class="mr-1 mb-1" nbButton size="small" status="warning"
                      (click)="addContent('h2', null, column)">Heading 2</button>
                      <button class="mr-1 mb-1" nbButton size="small" status="warning"
                      (click)="addContent('h3', null, column)">Heading 3</button>
                      <button class="mb-1" nbButton size="small" status="warning"
                      (click)="addContent('h4', null, column)">Heading 4</button>
                    </div>
                    <div class="text-center mb-2" *ngIf="column.insert">
                      <button class="mr-1 mb-1" nbButton size="small" [status]="column.addHeading?'success':'warning'"
                      (click)="toggleAddHeading(column)">{{column.addHeading?'Cancel':'Heading'}}</button>
                      <button class="mr-1 mb-1" nbButton size="small" status="warning"
                      (click)="addContent('img', null, column);deleteInsert(column)">Image</button>
                      <button class="mr-1 mb-1" nbButton size="small" status="warning"
                      (click)="addContent('video', null, column);deleteInsert(column)">Video</button>
                      <button class="mr-1 mb-1" nbButton size="small" status="warning"
                      (click)="addContent('pdf', null, column);deleteInsert(column)">PDF</button>
                      <button class="mr-1 mb-1" nbButton size="small" status="warning"
                      (click)="addContent('div', 'text', column, 'Edit Text');deleteInsert(column)">Text</button>
                      <button class="mb-1" nbButton size="small" status="warning"
                      (click)="addContent('hr', null, column)">Column Break</button>
                    </div>

                    <button class="block-center" nbButton size="small" outline [status]="column.insert?'success':'warning'"
                    (click)="toggleInsert(column)">{{column.insert?'Cancel':'Insert'}}</button>
                  </ng-template>
                </nb-card-body>
              </nb-card>
            </div>
            <div class="text-center mb-2" *ngIf="block.insertColumn">
              <p class="hint text-success">
                Select column <span class="text-warning">width</span> per screen <strong>size</strong>:
              </p>
              <ul class="hint">
                <li><span class="text-warning">LARGE</span> screens: <strong class="text-success">≥992px</strong></li>
                <li><span class="text-warning">MEDIUM</span> screens: <strong class="text-success">≥768px</strong></li>
                <li><span class="text-warning">SMALL</span> screens: <strong class="text-success">≥576px</strong></li>
              </ul>

              <div class="text-center mb-2">
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-lg-1')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-lg-1')">Large 1</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-lg-2')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-lg-2')">Large 2</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-lg-3')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-lg-3')">Large 3</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-lg-4')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-lg-4')">Large 4</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-lg-5')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-lg-5')">Large 5</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-lg-6')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-lg-6')">Large 6</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-lg-7')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-lg-7')">Large 7</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-lg-8')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-lg-8')">Large 8</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-lg-9')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-lg-9')">Large 9</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-lg-10')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-lg-10')">Large 10</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-lg-11')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-lg-11')">Large 11</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-lg-12')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-lg-12')">Large 12</button>
              </div>
              <div class="text-center mb-2">
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-md-1')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-md-1')">Medium 1</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-md-2')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-md-2')">Medium 2</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-md-3')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-md-3')">Medium 3</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-md-4')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-md-4')">Medium 4</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-md-5')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-md-5')">Medium 5</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-md-6')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-md-6')">Medium 6</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-md-7')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-md-7')">Medium 7</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-md-8')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-md-8')">Medium 8</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-md-9')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-md-9')">Medium 9</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-md-10')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-md-10')">Medium 10</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-md-11')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-md-11')">Medium 11</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-md-12')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-md-12')">Medium 12</button>
              </div>
              <div class="text-center mb-2">
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-sm-1')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-sm-1')">Small 1</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-sm-2')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-sm-2')">Small 2</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-sm-3')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-sm-3')">Small 3</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-sm-4')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-sm-4')">Small 4</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-sm-5')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-sm-5')">Small 5</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-sm-6')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-sm-6')">Small 6</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-sm-7')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-sm-7')">Small 7</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-sm-8')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-sm-8')">Small 8</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-sm-9')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-sm-9')">Small 9</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-sm-10')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-sm-10')">Small 10</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-sm-11')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-sm-11')">Small 11</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'col-sm-12')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'col-sm-12')">Small 12</button>
              </div>
              <div class="text-center mb-2">
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'mr-auto')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'mr-auto')">Force Margin Left</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'm-auto')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'm-auto')">Center Margins</button>
                <button class="mr-1 mb-1" nbButton size="small"
                [status]="blockHasClass(block.insertColumn, 'ml-auto')?'success':'warning'"
                (click)="toggleClass(block.insertColumn, 'ml-auto')">Force Margin Right</button>
              </div>
            </div>
            <div class="text-center mb-2" *ngIf="block.addHeading">
              <button class="mr-1 mb-1" nbButton size="small" status="warning"
              (click)="addContent('h1', 'col-12', block)">Heading 1</button>
              <button class="mr-1 mb-1" nbButton size="small" status="warning"
              (click)="addContent('h2', 'col-12', block)">Heading 2</button>
              <button class="mr-1 mb-1" nbButton size="small" status="warning"
              (click)="addContent('h3', 'col-12', block)">Heading 3</button>
              <button class="mb-1" nbButton size="small" status="warning"
              (click)="addContent('h4', 'col-12', block)">Heading 4</button>
            </div>
            <div class="text-center mb-2" *ngIf="block.insert">
              <button class="mr-1 mb-1" nbButton size="small" [status]="block.insertColumn?'success':'warning'"
              (click)="toggleInsertColumn(block)">{{block.insertColumn?'Cancel':'Column'}}</button>
              <button class="mr-1 mb-1" nbButton size="small" [status]="block.addHeading?'success':'warning'"
              (click)="toggleAddHeading(block)">{{block.addHeading?'Cancel':'Heading'}}</button>
              <button class="mb-1" nbButton size="small" status="warning"
              (click)="addContent('hr', 'col-11 m-auto', block)">Row Break</button>
            </div>

            <button class="block-center" nbButton size="small" outline [status]="block.insert?'success':'warning'"
            (click)="block.insertColumn?insertColumn(block):toggleInsert(block)">
              {{ block.insertColumn ? 'Insert Column' : block.insert ? 'Cancel' : 'Insert' }}
            </button>
          </ng-container>

         </nb-card-body>
       </nb-card>
     </div>
     <div class="text-center mb-2" *ngIf="addHeading">
       <button class="mr-1 mb-1" nbButton size="small" status="warning"
       (click)="addContent('h1')">Heading 1</button>
       <button class="mr-1 mb-1" nbButton size="small" status="warning"
       (click)="addContent('h2')">Heading 2</button>
       <button class="mr-1 mb-1" nbButton size="small" status="warning"
       (click)="addContent('h3')">Heading 3</button>
       <button class="mb-1" nbButton size="small" status="warning"
       (click)="addContent('h4')">Heading 4</button>
     </div>
     <div class="text-center mb-2" *ngIf="addBlock">
       <button class="mr-1 mb-1" nbButton size="small" status="warning"
       (click)="addContent('div', 'row');addHeading=addBlock=false">Row</button>
       <button class="mr-1 mb-1" nbButton size="small" [status]="addHeading?'success':'warning'"
       (click)="addHeading=!addHeading">{{addHeading?'Cancel':'Heading'}}</button>
       <button class="mr-1 mb-1" nbButton size="small" status="warning"
       (click)="addContent('img');addHeading=addBlock=false">Image</button>
       <button class="mr-1 mb-1" nbButton size="small" status="warning"
       (click)="addContent('video');addHeading=addBlock=false">Video</button>
       <button class="mr-1 mb-1" nbButton size="small" status="warning"
       (click)="addContent('pdf');addHeading=addBlock=false">PDF</button>
       <button class="mr-1 mb-1" nbButton size="small" status="warning"
       (click)="addContent('div', 'text', null, 'Edit Text');addHeading=addBlock=false">Text</button>
       <button class="mb-1" nbButton size="small" status="warning"
       (click)="addContent('hr')">Page Break</button>
     </div>
     <button class="block-center" nbButton size="small" outline [status]="addBlock?'success':'warning'"
     (click)="addBlock=!addBlock;addHeading=false">{{addBlock?'Cancel':'Add Block'}}</button>
     <input hidden type="file" #imgInput accept="image/*" (change)="addImage($event.target.files)">
     <input hidden type="file" #vidInput accept="video/*" (change)="addVideo($event.target.files)">
     <input hidden type="file" #pdfInput accept="application/pdf" (change)="addPDF($event.target.files)">
   </nb-card-body>
 </nb-card>
</div>
<hr class="mt-0">
<ng-container *ngIf="createPost; else update;">
  <button class="block-center mb10" nbButton size="medium" outline *ngIf="!schedulePost"
    status="success" (click)="publishPost()">Publish Post</button>
  <button class="block-center mb10" nbButton size="medium" outline  *ngIf="schedulePost" [disabled]="!dateScheduled||!timeScheduled"
    status="warning" (click)="postDatePost()">Schedule Post</button>
    <button class="block-center mb10" nbButton size="small" outline [disabled]="schedulePost&&!(dateScheduled||timeScheduled)"
    status="default" (click)="savePost()">Save To Drafts</button>
</ng-container>
<ng-template #update>
  <button class="block-center" nbButton size="medium" outline
    status="warning" (click)="savePost()">Update Post</button>
</ng-template>
