import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject, Observable } from 'rxjs';
import { Credentials, User } from '@app/models';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authUser: BehaviorSubject<firebase.User | firebase.auth.UserCredential>;
  public user: User;
  public userChanges: Observable<User[]>;

  constructor(
    private auth: AngularFireAuth,
    private firestore: AngularFirestore
  ) {
    this.authUser = new BehaviorSubject<firebase.User>(JSON.parse(localStorage.getItem('authUser')));
    auth.authState.subscribe(user => this.setAuthorizedUser(user));
    console.log('authUser.value: ', this.authUser.value as User);
  }

  get authenticated(): boolean {
    return this.authUser.value !== null;
  }

  setAuthorizedUser(user: firebase.User | firebase.auth.UserCredential) {
    localStorage.setItem('authUser', JSON.stringify(user));
    this.authUser.next(user);
    this.getUser(user);
    console.log("authenticated?", this.authenticated, this.authUser.value);
  }

  sendInvestorSignInLink({ email }: Credentials) {
    const actionCodeSettings = {
      url: 'https://pecanmilk.com/account/investor-sign-in/',
      handleCodeInApp: true,
    };
    return this.auth.sendSignInLinkToEmail(email, actionCodeSettings);
  }

  signInWithEmail(credentials: Credentials): Promise<firebase.User | firebase.auth.UserCredential> {
    return this.auth.signInWithEmailAndPassword(credentials.email, credentials.password);
  }

  signUp(credentials: Credentials): Promise<firebase.auth.UserCredential | User> {
    return this.auth.createUserWithEmailAndPassword(credentials.email, credentials.password);
  }

  storeUser(user: User): Promise<DocumentReference> {
    return this.firestore.collection<User>('users').add(user);
  }

  private getUser(user: any = this.authUser): void {
    if (user === null || user.uid === undefined) return;
    console.log("getting user by ID: ", user.uid);
    this.userChanges = this.firestore.collection<User>('users', ref => ref.where('uid', "==", user.uid)).valueChanges();
    this.userChanges.subscribe(u => (console.log("User info is: ", this.user = u[0])));
  }

  updateUser(user: User): Promise<void> {
    return this.firestore.collection<User>('users').doc(user.id).update(user);
  }

  qualifyUsername(qualifier: string): Observable<User[]> {
    return this.firestore.collection<User>('users', ref => ref.where('username', "==", qualifier.toLowerCase())).valueChanges();
  }

  logout() {
    console.log("removing logged in credentials.");
    this.auth.signOut();
    localStorage.removeItem('authUser');
    this.authUser.next(null);
  }

}
