import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NavStack } from '@app/models';
import { AuthService } from '@app/services';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {

  @Input() expanded: boolean;
  @Output() toggleMenu = new EventEmitter<any>();

  stack: NavStack[] = [];

  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  async ngOnInit(): Promise<void> {
    const role = await this.auth.user.role;
    switch (role) {
      case 'admin':
        this.stack.push(
          {
            id: 'home',
            title: 'Dashboard',
            type: 'item',
            icon: 'home',
            route: '/dashboard'
          },
          {
            id       : 'admin',
            title    : 'Administrator',
            type     : 'group',
            children : [
              {
                id       : 'workspace',
                title    : 'Workspace',
                type     : 'item',
                icon     : 'grid',
                route    : '/workspace',
              },
              {
                id       : 'users',
                title    : 'Users',
                type     : 'item',
                icon     : 'people',
                route    : '/users',
              },
              {
                id       : 'leads',
                title    : 'Leads',
                type     : 'item',
                icon     : 'person-done',
                route    : '/leads',
              },
              {
                id       : 'roles',
                title    : 'Roles',
                type     : 'item',
                icon     : 'bookmark',
                route    : '/roles',
              },
              {
                id       : 'settings',
                title    : 'Settings',
                type     : 'item',
                icon     : 'settings',
                route    : '/settings',
              },
            ]
          },
          {
            id       : 'manage',
            title    : 'Management',
            type     : 'group',
            children : [
              {
                id       : 'products',
                title    : 'Products',
                type     : 'item',
                icon     : 'pricetags',
                route    : '/products',
              },
              {
                id       : 'orders',
                title    : 'Orders',
                type     : 'item',
                icon     : 'swap',
                route    : '/orders',
              },
              {
                id       : 'subscriptions',
                title    : 'Subscriptions',
                type     : 'item',
                icon     : 'refresh',
                route    : '/subscriptions',
              },
              {
                id       : 'locations',
                title    : 'Store Locations',
                type     : 'item',
                icon     : 'pin',
                route    : '/locations',
              },
              {
                id       : 'deliveries',
                title    : 'Deliveries/Pickups',
                type     : 'item',
                icon     : 'car',
                route    : '/deliveries',
              },
              {
                id       : 'pickups',
                title    : 'Pickup Locations',
                type     : 'item',
                icon     : 'flag',
                route    : '/pickups',
              },
              {
                id       : 'messages',
                title    : 'Messages',
                type     : 'item',
                icon     : 'inbox',
                route    : '/messages',
                // badge    : {
                //     title    : '25',
                //     bg       : '#F44336',
                //     fg       : '#FFFFFF'
                // }
              },
              {
                id       : 'posts',
                title    : 'Posts',
                type     : 'item',
                icon     : 'globe',
                route    : '/posts',
              },
              {
                id       : 'activity',
                title    : 'Activity',
                type     : 'item',
                icon     : 'activity',
                route    : '/activity',
              },
              {
                id       : 'schedule',
                title    : 'Schedule',
                type     : 'item',
                icon     : 'calendar',
                route    : '/schedule',
              },
              {
                id       : 'payroll',
                title    : 'Payroll',
                type     : 'item',
                icon     : 'clipboard',
                route    : '/payroll',
              },
            ]
          },
          {
            id       : 'service',
            title    : 'Services',
            type     : 'group',
            children : [
              {
                id       : 'inquiries',
                title    : 'Inquiries',
                type     : 'item',
                icon     : 'question-mark-circle',
                route    : '/inquiries',
              },
              {
                id       : 'prospects',
                title    : 'Prospects',
                type     : 'item',
                icon     : 'person-add',
                route    : '/prospects',
              },
              {
                id       : 'support',
                title    : 'Support',
                type     : 'item',
                icon     : 'heart',
                route    : '/support',
              },
            ]
          },
        );
        break;
      case 'customer':
        this.stack.push(
          {
            id: 'profile',
            title: 'Profile',
            type: 'item',
            icon: 'home',
            route: '/profile'
          },
          {
            id       : 'settings',
            title    : 'Settings',
            type     : 'item',
            icon     : 'settings',
            route    : '/settings',
          },
          {
            id       : 'manage',
            title    : 'Management',
            type     : 'group',
            children : [
              {
                id       : 'orders',
                title    : 'Orders',
                type     : 'item',
                icon     : 'swap',
                route    : '/orders',
              },
              {
                id       : 'messages',
                title    : 'Messages',
                type     : 'item',
                icon     : 'inbox',
                route    : '/messages',
                // badge    : {
                //     title    : '25',
                //     bg       : '#F44336',
                //     fg       : '#FFFFFF'
                // }
              },
              {
                id       : 'activity',
                title    : 'Activity',
                type     : 'item',
                icon     : 'flip',
                route    : '/activity',
              },
              {
                id       : 'calendat',
                title    : 'Calendar',
                type     : 'item',
                icon     : 'calendar',
                route    : '/calendar',
              },
            ]
          },
          {
            id       : 'service',
            title    : 'Services',
            type     : 'group',
            children : [
              {
                id       : 'inquiries',
                title    : 'Inquiries',
                type     : 'item',
                icon     : 'question-mark-circle',
                route    : '/inquiries',
              },
              {
                id       : 'support',
                title    : 'Support',
                type     : 'item',
                icon     : 'heart',
                route    : '/support',
              },
            ]
          },
        );
        break;

      default:
        break;
    }
  }

  routeActive(route): boolean {
    return this.router.url.includes(route);
  }

  goTo(route): void {
    if (window.innerWidth < 576) this.toggleMenu.emit();
    this.router.navigate([route]);
  }

}
