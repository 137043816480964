<div class="nav-stack" [ngClass]="{'collapsed': !expanded}">
    <ng-container *ngFor="let item of stack">
        <div class="menu-item" (click)="goTo(item.route)" [title]="item.title" *ngIf="item.type==='item';else group;" [ngClass]="{'selected': routeActive(item.route)}">
            <nb-icon [icon]="item.icon + (routeActive(item.route)?'':'-outline')"></nb-icon>
            <span class="title">
                {{ item.title }}
            </span>
        </div>
        <ng-template #group>
            <span class="menu-title">
                <span class="title">
                    {{ item.title }}
                </span>
            </span>
            <ul class="menu-group">
                <li *ngFor="let link of item.children"
                    [title]="link.title" (click)="goTo(link.route)"
                    [ngClass]="{'selected': routeActive(link.route)}">
                    <nb-icon [icon]="link.icon + (routeActive(link.route)?'':'-outline')"></nb-icon>
                    <span class="title">
                        {{ link.title }}
                    </span>
                </li>
            </ul>
        </ng-template>
    </ng-container>
</div>