import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Pickup } from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class PickupService {
  private collectionName = 'pickups';

  constructor(private firestore: AngularFirestore) {}

  getPickups(): Observable<Pickup[]> {
    return this.firestore.collection<Pickup>(this.collectionName).valueChanges({ idField: 'id' });
  }

  addPickup(pickup: Pickup) {
    return this.firestore.collection<Pickup>(this.collectionName).add(pickup)
  }

  updatePickup(pickup: Pickup) {
    return this.firestore.collection<Pickup>(this.collectionName).doc(pickup.id).update(pickup);
  }

  deletePickup(id: string) {
    return this.firestore.collection<Pickup>(this.collectionName).doc(id).delete();
  }
}
