<div class="overlay" [ngClass]="{'show': env.overlay}">
    <nb-card [nbSpinner]="env.loadOverlay">
        <nb-card-body *ngIf="env.overlay">
          <app-order *ngIf="!!env.display.order" [id]="env.display.order" (hide)="closeOverlay()"></app-order>
          <app-post *ngIf="!!env.display.post" [slug]="env.display.post" (hide)="closeOverlay()"></app-post>
          <app-product *ngIf="!!env.display.product" [slug]="env.display.product" (hide)="closeOverlay()"></app-product>
          <app-location *ngIf="!!env.display.location" [id]="env.display.location" (hide)="closeOverlay()"></app-location>
        </nb-card-body>
        <button class="close-overlay" nbButton ghost status="danger" (click)="closeOverlay()">
            <nb-icon icon="close-circle"></nb-icon>
        </button>
    </nb-card>
</div>
