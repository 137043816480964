import { AngularFireStorage } from '@angular/fire/storage';
import { BlogService } from '@app/services';
import { Component, OnInit, EventEmitter, Input, Output, NgZone, ViewChild, ElementRef } from '@angular/core';
import { ContentBlock, Post } from '@app/models';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {

  @ViewChild('imgInput') imageInput: ElementRef<HTMLInputElement>;
  @ViewChild('vidInput') videoInput: ElementRef<HTMLInputElement>;
  @ViewChild('pdfInput') pdfInput: ElementRef<HTMLInputElement>;
  @Input() slug: string;
  @Output() hide = new EventEmitter<void>();
  env = environment;
  post: Post = { content: { featured: { paragraph: false }, blocks: [] } };
  createPost: boolean;
  schedulePost: boolean;
  dateScheduled: Date;
  timeScheduled: Date;
  statuses = ['published', 'scheduled', 'drafted', 'trashed'];
  types = ['post', 'feature', 'recipe', 'media', 'news', 'customer', 'testimonial'];
  postType = {
    post: "Blog Post",
    feature: "Featured Post",
    recipe: "Recipe",
    media: "Media Publicity",
    news: "Co-op News/Updates",
    customer: "Featured Customer",
    testimonial: "Testimonial"
  }
  imagesRemoved: string[] = [];
  videosRemoved: string[] = [];
  pdfsRemoved: string[] = [];

  editHero = false;
  heroInfo: any = [];
  hero: File;
  uploadingHero: boolean;
  heroUpload: Observable<number>;
  heroDeleted: string;

  addBlock: boolean;
  addHeading: boolean;
  selectedBlock: ContentBlock;

  featured: File;
  featuredDeleted: string;
  featuredInfo: any = [];
  featuredUpload: Observable<number>;
  editFeatured = false;
  uploadingFeatured: boolean;

  constructor(
    private blogService: BlogService,
    private storage: AngularFireStorage,
    private sanitizer: DomSanitizer,
    private zone: NgZone
  ) { }

  vali(e) {
    console.log('validate: ', e);
  }

  ngOnInit(): void {
    if (this.slug === 'new') this.createPost = true;
    else this.blogService.getPost(this.slug).subscribe(posts => {
      this.post = posts[0];
      console.log('Post Overlay: ', this.post);
      if (!this.post.content) this.post.content = { featured: {}, blocks: [] };
      if (!this.post.content.featured.paragraph) this.post.content.featured.paragraph = false;
      if (this.post.status === 'scheduled') this.timeScheduled = new Date(this.post.date);
    });
  }

  do(e, p: HTMLInputElement) {
    console.log('E', e, p.classList, p.classList.contains('ng-pristine'));
  }

  newDate() {
    return new Date();
  }

  setScheduledDateTime(creating?: boolean) {
    this.post.date = [
      (creating ? this.post.date : this.dateScheduled.toJSON()).split('T')[0],
      this.timeScheduled.toJSON().split('T')[1]].join('T');
    this.post.status = 'scheduled';
  }

  drop(event: CdkDragDrop<string[]>, list?: any[]) {
    moveItemInArray(list || this.post.content.blocks, event.previousIndex, event.currentIndex);
  }

  updatePostSlug(title: string) {
    this.post.slug = title.toLocaleLowerCase().replace(/[`<>!_~@$%^&*?:;"'+=(),{}|.[\\\]]+/g, '')
      .replace(/[\s]{2,}/g, ' ').trim().replace(/\s/g, '-');
  }

  async savePost() {
    if (!this.post.title.trim()) return alert("Post title is required.");
    else if (!this.post.page.trim()) return alert("Page title is required.");
    const uploads = [];

    if (this.editHero) uploads.push(this.uploadHero());
    else if (this.heroDeleted) uploads.push(this.blogService.deleteStored(this.heroDeleted));

    if (this.editFeatured) uploads.push(this.uploadFeatured());
    else if (this.featuredDeleted) uploads.push(this.blogService.deleteStored(this.featuredDeleted));

    if (this.post.status==='scheduled') this.setScheduledDateTime();

    await this.uploadImages();
    await this.uploadPDFs();
    await this.uploadVideos();
    if (uploads.length) await Promise.all(uploads).then(() => this.uploadingHero = false);
    console.log('Saving Post: ', this.post);

    this.blogService.validatePost(this.post.slug).subscribe(assert =>
      (assert.empty || assert.docs.length === 1 && assert.docs[0].id === this.post.id) ?
        this.blogService.updatePost(this.post).then(() => this.hide.emit())
          .catch(error => console.log("There was an error saving the post: ", error)) :
        alert(`A post with the slug '${this.post.slug}' is already in use.`));
  }

  async publishPost() {
    await this.uploadImages();
    await this.uploadPDFs();
    await this.uploadVideos();
    const post: Post = this.post;
    post.date = new Date().toJSON(), post.status = 'published';
    if (!post.type) post.type = 'post';
    this.blogService.validatePost(post.slug).subscribe(assert => assert.empty ?
      (this.blogService.addPost(post).then(() => this.hide.emit())) :
      (alert(`A post with the slug '${post.slug}' is already in use.`)));
  }

  async postDatePost() {
    await this.uploadImages();
    await this.uploadPDFs();
    await this.uploadVideos(), this.setScheduledDateTime(true);
    const post: Post = this.post;
    this.blogService.validatePost(post.slug).subscribe(assert => assert.empty ?
      (this.blogService.addPost(post).then(() => this.hide.emit())) :
      (alert(`A post with the slug '${post.slug}' is already in use.`)));
  }

  addContent(tag: string, classes?: string, block?: any, html?: string) {
    const content: ContentBlock = { tag }
    if (classes) content.class = classes;
    if (html) content.html = html;
    if (block) {
      if (!block.content) block.content = [];
      block.content.push(content);
    } else this.post.content.blocks.push(content);
  }

  removeContent(index: number, list?: ContentBlock[]) {
    const removeImage = downloadUrl => { this.imagesRemoved.push(downloadUrl); };
    function filterRemoveImages(block: ContentBlock) {
      const imgsRemoved = block.content.filter(block => block.tag === 'img');
      imgsRemoved.forEach(imgBlock => removeImage(imgBlock.image || imgBlock.imageRemoved))
    }
    if (list) {
      console.log("remove conent called on content list: ", list, index);
      if (list[index].tag === 'img') removeImage(list[index].image || list[index].imageRemoved)
      else if (list[index].tag === 'div' && list[index].class.includes('column')) filterRemoveImages(list[index])
      else if (list[index].tag === 'div' && list[index].class.includes('row'))
        list[index].content.forEach(block => {
          if (block.tag === 'div' && block.class.includes('column')) filterRemoveImages(block);
        })
      list.splice(index, 1);
    } else {
      if (this.post.content.blocks[index].tag === 'img') {
        if (this.post.content.blocks[index].imageRemoved)
          removeImage(this.post.content.blocks[index].imageRemoved);
        else if (this.post.content.blocks[index].image)
          removeImage(this.post.content.blocks[index].image);
      }
      this.post.content.blocks.splice(index, 1);
    }
  }

  blockHasClass(block: ContentBlock, c: string): boolean {
    return block.class.includes(c + ' ');
  }

  insertColumn(block: ContentBlock) {
    if (!block.content) block.content = [];
    block.insertColumn.tag = 'div', block.insertColumn.class += ' column', block.content.push(block.insertColumn),
    delete block.insertColumn, delete block.insert;
  }

  toggleClass(block: ContentBlock, c: string) {
    if (!block.class) block.class = '';
    block.class.includes(c + ' ') ? block.class = block.class.replace(c + ' ', '') : block.class += c + ' ';
  }

  toggleAddHeading(block: ContentBlock) {
    if (block.insertColumn) delete block.insertColumn;
    block.addHeading ? delete block.addHeading : block.addHeading = true;
  }

  toggleInsertColumn(block: ContentBlock) {
    if (block.addHeading) delete block.addHeading;
    block.insertColumn ? delete block.insertColumn : block.insertColumn = { class: '', content: [] };
  }

  toggleInsert(block: ContentBlock) {
    block.insert ? this.deleteInsert(block) : block.insert = true;
  }

  deleteInsert(block: ContentBlock) {
    delete block.insert, delete block.addHeading, delete block.insertColumn;
  }

  toggleRemove(block: ContentBlock) {
    block.remove ? delete block.remove : block.remove = true;
  }

  uploadHero() {
    return new Promise((resolve, reject) => {
      if (this.heroInfo.length && this.hero) {
        const ext = this.heroInfo[0].name.split('.').pop();
        const name = this.post.slug + '.' + ext;
        const task = this.blogService.uploadHero(this.hero, name);

        this.uploadingHero = true;
        // observe percentage changes
        this.heroUpload = task.percentageChanges();
        // get notified when the download URL is available
        task.then(stored => {
          this.storage.ref(stored.metadata.fullPath).getDownloadURL().subscribe(url => {
            this.uploadingHero = false;
            this.post.hero = url;
            if (this.post.heroRemoved) this.post.heroRemoved = null;
            console.log('hero uploaded: ', stored);
            resolve(true);
          });
        }).catch(error => {
          console.log('Error uploading image: ', error);
          this.uploadingHero = false;
          reject();
        });
      } else resolve(false);
    });
  }

  selectHero(input) {
    if (!this.heroInfo.length) input.click()
    this.editHero = true;
  }

  addHero(event: FileList) {
    const element = event[0];
    const reader = new FileReader();
    console.log("file event: ", event);
    this.heroInfo.push({ name: element.name });
    reader.onload = (e: any) => this.heroInfo[0].preview = e.target.result;
    reader.readAsDataURL(this.hero = element);
  }

  removeHero(input?) {
    if (this.post.hero) this.heroDeleted = this.post.hero, this.post.hero = null, this.editHero = true;
    if (input) input.value = '', this.heroInfo = [], this.hero = null;
    this.post.heroRemoved = true;
  }

  retoreHero() {
    if (this.heroDeleted) {
      this.post.hero = this.heroDeleted;
      this.post.heroRemoved = this.heroDeleted = null;
    }
    this.editHero = false;
  }

  addFeatured(event: FileList) {
    const element = event[0];
    const reader = new FileReader();
    this.featuredInfo = [{ name: element.name }];
    reader.onload = (e: any) => this.featuredInfo[0].preview = e.target.result;
    reader.readAsDataURL(this.featured = element);
  }

  removeFeatured(input) {
    input.value = '', this.featuredInfo = [], this.featured = null;
  }

  selectFeatured(input) {
    if (!this.featuredInfo.length) input.click()
    this.editFeatured = true;
  }

  retoreFeatured() {
    if (this.featuredDeleted) {
      this.post.content.featured.image = this.featuredDeleted;
      this.featuredDeleted = null;
    }
    this.editFeatured = false;
  }

  deleteFeatured() {
    this.featuredDeleted = this.post.content.featured.image;
    this.post.content.featured.image = null;
  }

  restoreFeatured() {
    this.post.content.featured.image = this.featuredDeleted;
    this.featuredDeleted = null;
  }

  uploadFeatured() {
    return new Promise((resolve, reject) => {
      if (this.featuredInfo.length && this.featured) {
        const ext = this.featuredInfo[0].name.split('.').pop();
        const name = this.post.slug + '.' + ext;
        const task = this.blogService.uploadFeatured(this.featured, name);

        this.uploadingFeatured = true;
        // observe percentage changes
        this.featuredUpload = task.percentageChanges();
        // get notified when the download URL is available
        task.then(stored => {
          this.storage.ref(stored.metadata.fullPath).getDownloadURL().subscribe(url => {
            this.uploadingFeatured = false;
            this.post.content.featured.image = url;
            console.log('featured uploaded: ', stored);
            resolve(true);
          });
        }).catch(error => {
          console.log('Error uploading image: ', error);
          this.uploadingFeatured = false;
          reject();
        });
      } else resolve(false);
    });
  }

  selectImage(block: ContentBlock) {
    this.selectedBlock = block;
    // this.selectedBlock.editImage = true;
    this.imageInput.nativeElement.click();
  }

  addImage(files: FileList) {
    if (!this.selectedBlock) return;
    if (!this.selectedBlock.imgInfo) this.selectedBlock.imgInfo = [];
    const element = files[0];
    const reader = new FileReader();
    this.selectedBlock.imgInfo.push({ name: element.name });
    reader.onload = e => (this.selectedBlock.imgInfo[0].preview = e.target.result, this.imageInput.nativeElement.value = '');
    reader.readAsDataURL(this.selectedBlock.imgEl = element);
    console.log("image files: ", files, this.selectedBlock, this.post.content.blocks);
  }

  removeImage(block: ContentBlock) {
    if (block.image) {
      block.imageRemoved = block.image;
      block.image = null;
    }
    block.imgInfo = [];
  }

  restoreImage(block: ContentBlock) {
    if (block.imageRemoved) { block.image = block.imageRemoved; }
  }

  selectPDF(block: ContentBlock) {
    this.selectedBlock = block;
    this.pdfInput.nativeElement.click();
  }

  async addPDF(files: FileList) {
    if (!this.selectedBlock) return;
    if (!this.selectedBlock.pdfInfo) this.selectedBlock.pdfInfo = [];
    const element = files[0];
    const reader = new FileReader();
    const buffer = await element.arrayBuffer();
    const preview = new Uint8Array(buffer);
    this.selectedBlock.pdfInfo.push({ name: element.name, preview });
    this.selectedBlock.pdfEl = element, reader.onload = e => this.pdfInput.nativeElement.value = '';
    console.log("pdf files: ", files, this.selectedBlock, this.post.content.blocks);
  }

  removePDF(block: ContentBlock) {
    if (block.pdf) {
      block.pdfRemoved = block.pdf;
      block.pdf = null;
    }
    block.pdfInfo = [];
  }

  restorePDF(block: ContentBlock) {
    if (block.pdfRemoved) { block.pdf = block.pdfRemoved; }
  }

  selectVideo(block: ContentBlock) {
    this.selectedBlock = block;
    this.videoInput.nativeElement.click();
  }

  addVideo(files: FileList) {
    if (!this.selectedBlock) return;
    if (!this.selectedBlock.vidInfo) this.selectedBlock.vidInfo = [];
    const element = files[0];
    const reader = new FileReader();
    this.selectedBlock.vidInfo.push({ name: element.name, preview: URL.createObjectURL(element) });
    this.selectedBlock.vidEl = element, reader.onload = e => this.videoInput.nativeElement.value = '';
    console.log("video files: ", files, this.selectedBlock, this.post.content.blocks);
  }

  removeVideo(block: ContentBlock) {
    if (block.video) {
      block.videoRemoved = block.video;
      block.video = null;
    }
    block.vidInfo = [];
  }

  restoreVideo(block: ContentBlock) {
    if (block.videoRemoved) { block.video = block.videoRemoved; }
  }

  trustVideo(url: string) {
    console.log("trusting url: ", url);
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  async uploadImages() {
    const promises: Promise<any>[] = [];
    const blocks = this.post.content.blocks;
    const imgsRemoved = this.post.content.blocks.filter(block => block.tag === 'img' && block.imageRemoved)
    const imgUpload = (block: ContentBlock) => {
      return new Promise((resolve, reject) => {
        const name = block.imgInfo[0].name;
        const task = this.blogService.uploadImage(block.imgEl, name);

        block.uploadImage = true, block.imgUpload = task.percentageChanges();
        task.then(stored => {
          this.storage.ref(stored.metadata.fullPath).getDownloadURL().subscribe(url => {
            block.image = url;
            delete block.imgEl;
            delete block.imgInfo;
            delete block.imgUpload;
            delete block.uploadImage;
            delete block.imageRemoved;
            console.log('image uploaded: ', stored);
            resolve(true);
          });
        }).catch(error => {
          console.log('Error uploading image: ', error);
          block.uploadImage = false;
          reject();
        });
      })
    }
    blocks.forEach((block, i) => {
      if (block.tag === 'img' && !!block.imgEl && block.imgInfo.length) {
        console.log('uploading block,', block);
        promises.push(imgUpload(block))
      } else if (block.tag === 'div' && block.class.includes('row')) {
        console.log('block row found.');
        block.content.forEach(contentBlock => {
          if (contentBlock.tag === 'div' && contentBlock.class.includes('column')) {
            console.log('block column found.');
            const imgsAdded = contentBlock.content.filter(b => b.tag === 'img' && !!b.imgEl && b.imgInfo.length);
            const imgsRemoved = contentBlock.content.filter(b => b.tag === 'img' && b.imageRemoved);
            imgsAdded.map(imgBlock => promises.push(imgUpload(imgBlock)));
            imgsRemoved.forEach(imgBlock => imgsRemoved.push(imgBlock));
          }
        })
      }
    });
    console.log('images removed: ', this.imagesRemoved)
    this.imagesRemoved.forEach(url => promises.push(this.blogService.deleteStored(url)));
    imgsRemoved.forEach(block => promises.push(this.blogService.deleteStored(block.imageRemoved).then(() => delete block.imageRemoved)));
    await Promise.all(promises).then(() => (console.log('blocks updated: ', blocks), this.post.content.blocks = blocks));
  }

  async uploadPDFs() {
    const promises: Promise<any>[] = [];
    const blocks = this.post.content.blocks;
    const pdfsRemoved = this.post.content.blocks.filter(block => block.tag === 'pdf' && block.pdfRemoved)
    const pdfUpload = (block: ContentBlock) => {
      return new Promise((resolve, reject) => {
        const name = block.pdfInfo[0].name;
        const task = this.blogService.uploadPDF(block.pdfEl, name);

        block.uploadPDF = true, block.pdfUpload = task.percentageChanges();
        task.then(stored => {
          this.storage.ref(stored.metadata.fullPath).getDownloadURL().subscribe(url => {
            block.pdf = url;
            delete block.pdfEl;
            delete block.pdfInfo;
            delete block.pdfUpload;
            delete block.uploadPDF;
            delete block.pdfRemoved;
            console.log('pdf uploaded: ', stored);
            resolve(true);
          });
        }).catch(error => {
          console.log('Error uploading pdf: ', error);
          block.uploadPDF = false;
          reject();
        });
      })
    }
    blocks.forEach((block, i) => {
      if (block.tag === 'pdf' && !!block.pdfEl && block.pdfInfo.length) {
        console.log('uploading block,', block);
        promises.push(pdfUpload(block))
      } else if (block.tag === 'div' && block.class.includes('row')) {
        console.log('block row found.');
        block.content.forEach(contentBlock => {
          if (contentBlock.tag === 'div' && contentBlock.class.includes('column')) {
            console.log('block column found.');
            const pdfsAdded = contentBlock.content.filter(b => b.tag === 'pdf' && !!b.pdfEl && b.pdfInfo.length);
            const pdfsRemoved = contentBlock.content.filter(b => b.tag === 'pdf' && b.pdfRemoved);
            pdfsAdded.map(pdfBlock => promises.push(pdfUpload(pdfBlock)));
            pdfsRemoved.forEach(pdfBlock => pdfsRemoved.push(pdfBlock));
          }
        })
      }
    });
    console.log('pdfs removed: ', this.pdfsRemoved)
    this.pdfsRemoved.forEach(url => promises.push(this.blogService.deleteStored(url)));
    pdfsRemoved.forEach(block => promises.push(this.blogService.deleteStored(block.pdfRemoved).then(() => delete block.pdfRemoved)));
    await Promise.all(promises).then(() => (console.log('blocks updated: ', blocks), this.post.content.blocks = blocks));
  }

  async uploadVideos() {
    const promises: Promise<any>[] = [];
    const blocks = this.post.content.blocks;
    const vidsRemoved = this.post.content.blocks.filter(block => block.tag === 'video' && block.videoRemoved)
    const vidUpload = (block: ContentBlock) => {
      return new Promise((resolve, reject) => {
        const name = block.vidInfo[0].name;
        const task = this.blogService.uploadVideo(block.vidEl, name);

        block.uploadVideo = true, block.vidUpload = task.percentageChanges();
        task.then(stored => {
          this.storage.ref(stored.metadata.fullPath).getDownloadURL().subscribe(url => {
            block.video = url;
            delete block.vidEl;
            delete block.vidInfo;
            delete block.vidUpload;
            delete block.uploadVideo;
            delete block.videoRemoved;
            console.log('video uploaded: ', stored);
            resolve(true);
          });
        }).catch(error => {
          console.log('Error uploading video: ', error);
          block.uploadVideo = false;
          reject();
        });
      })
    }
    blocks.forEach((block, i) => {
      if (block.tag === 'video' && !!block.vidEl && block.vidInfo.length) {
        console.log('uploading block,', block);
        promises.push(vidUpload(block))
      } else if (block.tag === 'div' && block.class.includes('row')) {
        console.log('block row found.');
        block.content.forEach(contentBlock => {
          if (contentBlock.tag === 'div' && contentBlock.class.includes('column')) {
            console.log('block column found.');
            const vidsAdded = contentBlock.content.filter(b => b.tag === 'video' && !!b.vidEl && b.vidInfo.length);
            const vidsRemoved = contentBlock.content.filter(b => b.tag === 'video' && b.videoRemoved);
            vidsAdded.map(vidBlock => promises.push(vidUpload(vidBlock)));
            vidsRemoved.forEach(vidBlock => vidsRemoved.push(vidBlock));
          }
        })
      }
    });
    console.log('videos removed: ', this.videosRemoved)
    this.videosRemoved.forEach(url => promises.push(this.blogService.deleteStored(url)));
    vidsRemoved.forEach(block => promises.push(this.blogService.deleteStored(block.videoRemoved).then(() => delete block.videoRemoved)));
    await Promise.all(promises).then(() => (console.log('blocks updated: ', blocks), this.post.content.blocks = blocks));
  }

}
