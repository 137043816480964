import { User } from '@app/models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() menuOpen: boolean;
  @Input() mobileMenuOpen: boolean;
  @Input() shortcutSize: string;
  @Output() openMenu = new EventEmitter<any>();
  @Output() toggleMenu = new EventEmitter<any>();

  user: User = {};

  get portalTitle() {
    const role = this.auth.user && this.auth.user.role;
    return (
      role === 'admin' ? 'Admin Portal' :
      role === 'owner' ? 'Owner Portal' :
      role === 'customer' ? 'Customer Portal' :
      role === 'store' ? 'Store Portal' :
      role === 'prospee' ? 'Worker Portal' : 'Portal'
    );
  }

  constructor(
    private auth: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.user = this.auth.user;
  }

  logout() {
    this.router.navigate(['/authenticate/logout']);
  }

  userFirst(): string {
    return this.auth.user && this.auth.user.first;
  }

  emitOpenMenu(bool: boolean) {
    this.openMenu.emit(bool);
  }

  emitToggleMenu() {
    this.toggleMenu.emit();
  }

}
