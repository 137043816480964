<div class="row">
  <div class="col-md-4 pr0">
    <nb-card>
      <nb-card-body class="text-center">
        <h1 class="text-success font-weight-light">Store Location</h1>
        <hr>
        <span style="font-size: 16px;">
          {{ location.name }}
        </span>
      </nb-card-body>
    </nb-card>
    <nb-card>
      <nb-card-body>
        <h2 class="text-center text-success font-weight-light">
          {{ location.address }}
        </h2>
        <hr>
        <p>{{ location.description }}</p>
      </nb-card-body>
    </nb-card>
    <nb-card>
      <nb-card-body>
        <app-google-places (setAddress)="setAddress($event)"
          adressType="geocode"></app-google-places>
      </nb-card-body>
    </nb-card>
    <button class="block-center" *ngIf="locationSet" nbButton size="medium" outline
      status="warning" (click)="saveAddress()">Update Address</button>
  </div>
  <div class="col-md-8">
    <h2 class="text-center text-success font-weight-light mb-4">Map</h2>
    <nb-card>
      <nb-card-body>
        <google-map [options]="mapOptions"
          [center]="center"
          [zoom]="mapOptions.zoom" width="100%">
          <map-marker *ngIf="locationSet"
            [position]="{ lat: center.lat, lng: center.lng }"
            [options]="markerOptions"></map-marker>
        </google-map>
      </nb-card-body>
    </nb-card>
  </div>
</div>
