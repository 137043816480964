import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Post } from '@app/models';
import 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
  ) { }


  getPost(slug: string) {
    return this.firestore.collection<Post>('posts', ref => ref.where('slug', '==', slug)).valueChanges({ idField: 'id' });
  }

  getPosts() {
    return this.firestore.collection<Post>('posts', ref => ref.orderBy('date', 'desc')).valueChanges({ idField: 'id' });
  }

  deleteStored(downloadUrl) {
    return this.storage.storage.refFromURL(downloadUrl).delete();
  }

  uploadImage(image: File, name: string) {
    return this.storage.upload('images/post-images/' + name, image);
  }

  uploadVideo(video: File, name: string) {
    return this.storage.upload('videos/post-videos/' + name, video);
  }

  uploadPDF(pdf: File, name: string) {
    return this.storage.upload('pdfs/post-pdfs/' + name, pdf);
  }

  uploadHero(image: File, name: string) {
    return this.storage.upload('images/post-hero/' + name, image);
  }

  validatePost(slug: string) {
    return this.firestore.collection<Post>('posts', ref => ref.where('slug', '==', slug)).get();
  }

  addPost(post: Post) {
    return this.firestore.collection<Post>('posts').add(post);
  }

  updatePost(post: Post) {
    return this.firestore.collection<Post>('posts').doc(post.id).update(post);
  }

  uploadFeatured(image: File, name: string) {
    return this.storage.upload('images/post-featured/' + name, image);
  }

}
