import { OrderService, AuthService } from '@app/services';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Order, orderNote, User } from '@app/models';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  @Input() id: string;
  @Output() hide = new EventEmitter<void>();
  env = environment;
  order = false as unknown as Order;
  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  note: string;
  deliveryDays = [
    { day: 'Sunday', dayOfWeek: 0 },
    { day: 'Wednesday', dayOfWeek: 3 },
  ]

  get productTotal(): number {
    return this.order && (this.order.orderType === 'client' || this.order.orderType === 'public') ? this.order.products.map(p => p.quantity).reduce((a, b) => a + b) : 0;
  }

  get user(): User {
    return this.auth.user
  }

  get nextDeliveryDay(): string {
    return this.deliveryDays.find(d => d.dayOfWeek === this.nextDeliverDayOfWeek).day
  }

  get nextDeliverDayOfWeek(): number {
    const d = new Date().getDay(), deliveryDay = this.deliveryDays.find(day => d < day.dayOfWeek)
    return deliveryDay ? deliveryDay.dayOfWeek : this.deliveryDays[0].dayOfWeek;
  }

  constructor(
    private orderService: OrderService,
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    console.log("order")
    this.orderService.getOrder(this.id).subscribe(orders => {
      this.order = orders[0];
      console.log('Order Overlay: ', this.order);
    });
  }

  commalize(n: any) {
    if (!n) { return '0.00'; }
    const digits = Number(n).toFixed(2).split('').reverse();
    const decimals = digits.splice(0, 3);
    for (let i = 0, l = digits.length; i < l; i++) { if (i > 0 && i % 3 === 0) { digits[i] += ','; } }
    return decimals.concat(digits).reverse().join('');
  }


  formatDate(dateStr, spelledOut?: boolean) {
    if (!dateStr) return;
    const d = new Date(dateStr), parts = d.toLocaleDateString().split('/');
    if (parts[0].length < 2) { parts[0] = '0' + parts[0]; }
    if (parts[1].length < 2) { parts[1] = '0' + parts[1]; }
    return spelledOut ? `${this.months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}` : `${parts[2]}-${parts[0]}-${parts[1]}`;
  }

  formatTime(dateStr, getTime?: boolean) {
    if (!dateStr) return;
    const parts = new Date(dateStr).toLocaleTimeString().split(':');
    return `${parts[0]}:${parts[1]}${parts[2].replace(/\d/g, '').replace(' ', '').toLowerCase()}`;
  }

  nextDeliveryDate() {
    let date = new Date();
    return date.setDate(date.getDate() + (this.nextDeliverDayOfWeek + 7 - date.getDay()) % 7), date;
  }

  addNote() {
    if (!(this.note && this.note.trim())) return;
    const note: orderNote = {
      note: this.note,
      user: {
        name: this.auth.user.name,
        uid: this.auth.user.uid
      },
      createdAt: new Date().toJSON()
    }
    this.orderService.addOrderNote(this.order, note).then(() => this.note = '');
  }

  removeNote(note: orderNote) {
    if (confirm('Delete this note?')) {
      if (!this.order.notes) this.order.notes = [];
      this.order.notes = this.order.notes.filter(n => !(n.createdAt === note.createdAt && n.user.uid === note.user.uid))
      this.orderService.updateOrder(this.order);
    }
  }


}
